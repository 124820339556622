import { baseSplitApi } from "../../Services/BaseApi"
import { IStore } from "../../Types"
import { UpdateStorePayload } from "./Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listStores: build.query<IStore[], string>({
            query: (searchQuery) => '/stores?q=' + searchQuery,
            providesTags: ["Stores"]
        }),
        getStore: build.query<IStore, { storeId: string, productId?: string }>({
            query: ({ storeId, productId }) => '/stores/' + storeId + (productId ? '?productId=' + productId : ''),
            providesTags: ["Stores"]
        }),
        addStore: build.mutation<IStore, string>({
            query: (name) => ({
                url: '/stores',
                method: 'POST',
                body: { name },
            }),
            invalidatesTags: ["Stores"]
        }),
        updateStore: build.mutation<void, UpdateStorePayload>({
            query: (payload) => ({
                url: '/stores/' + payload.storeId,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ["Stores"]
        }),
        deleteStores: build.mutation<void, string[]>({
            query: (body) => ({
                url: '/stores',
                method: 'DELETE',
                body,
            }),
            invalidatesTags: ["Stores"]
        }),
        deleteStore: build.mutation<void, string>({
            query: (storeId) => ({
                url: '/stores/' + storeId,
                method: 'DELETE',
            }),
            invalidatesTags: ["Stores"]
        }),
    }),
    overrideExisting: false,
})

export const {
    useListStoresQuery,
    useGetStoreQuery,
    useAddStoreMutation,
    useUpdateStoreMutation,
    useDeleteStoresMutation,
    useDeleteStoreMutation,
} = api