import { baseSplitApi } from "../../Services/BaseApi"
import { IProduct } from "../../Types"
import { UpdateProductPayload } from "./Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        getProduct: build.query<IProduct, string>({
            query: (productId) => '/products/' + productId,
            providesTags: ["Products"],
        }),
        getRecentProducts: build.query<IProduct[], void>({
            query: () => '/products-recent',
            providesTags: ["RecentProducts"],
        }),
        listProducts: build.query<IProduct[], { q?: string, title?: string }>({
            query: ({ q, title }) => '/products' + (q ? '?q=' + q : '') + (title ? '?title=' + title : ''),
            providesTags: ["Products"]
        }),
        findProductsByBarcode: build.query<IProduct[], { sku: string }>({
            query: ({ sku }) => '/products?sku=' + sku,
            providesTags: ["Products"]
        }),
        addProduct: build.mutation<IProduct, string>({
            query: (title) => ({
                url: '/products',
                method: 'POST',
                body: { title },
            }),
            invalidatesTags: ["Products"]
        }),
        updateProduct: build.mutation<void, UpdateProductPayload>({
            query: (payload) => ({
                url: '/products/' + payload.productId,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ["Products"]
        }),
        deleteProductImage: build.mutation<void, { productId: string, imageIndex: number }>({
            query: ({ productId, imageIndex }) => ({
                url: '/products/' + productId + '/image',
                method: 'DELETE',
                body: { imageIndex },
            }),
            invalidatesTags: ["Products"]
        }),
        deleteProducts: build.mutation<void, string[]>({
            query: (body) => ({
                url: '/products',
                method: 'DELETE',
                body,
            }),
            invalidatesTags: ["Products"]
        }),
        deleteProduct: build.mutation<void, string>({
            query: (productId) => ({
                url: '/products/' + productId,
                method: 'DELETE',
            }),
            invalidatesTags: ["Products"]
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetProductQuery,
    useGetRecentProductsQuery,
    useListProductsQuery,
    useFindProductsByBarcodeQuery,
    useAddProductMutation,
    useUpdateProductMutation,
    useDeleteProductImageMutation,
    useDeleteProductsMutation,
    useDeleteProductMutation,
} = api