import { Button, Col, Row } from "react-bootstrap"
import { ReactNode } from "react"

function TitleButton({ title, description, buttonText, className, onClick }: { title: ReactNode | string, description?: string, buttonText?: ReactNode | string, className?: string, onClick?: () => void }) {

    return (
        <Row className={className}>
            <Col>
                <div className="fw-bold">{title}</div>
                {description ? <div>{description}</div> : null}
            </Col>
            {buttonText ? <Col xs="auto" className="text-end">
                <Button
                    variant="link"
                    size="sm"
                    onClick={onClick}
                >
                    {buttonText}
                </Button>
            </Col> : null}
        </Row>
    )
}

export default TitleButton