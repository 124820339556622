import { useAuth0 } from "@auth0/auth0-react";
import { Breadcrumb, Card, Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next";

function Account() {

    const { user } = useAuth0();
    const { t } = useTranslation()

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">{t('homeTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{t('accountTitle')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>{t('profileTitle')}</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>{t('nameTitle')}</Col>
                                <Col>{user?.name}</Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Account