import { useEffect, useState } from "react"
import { Col, Form, ListGroup, Offcanvas, Row } from "react-bootstrap"
import { skipToken } from "@reduxjs/toolkit/query"
import { IProduct } from "../../Types"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Store/Store"
import { showProductFinder } from "../../Store/Product/Product.slice"
import { useForm } from "react-hook-form"
import React from "react"
import { useGetRecentProductsQuery, useListProductsQuery } from "../../Store/Product/Product.service"
import { useGetStoreQuery } from "../../Store/Store/Store.service"
import { useTranslation } from "react-i18next"

const FTIMEOUT = 250

function ProductFinder({ onProductSelect }: { onProductSelect: (product: IProduct) => void }) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [productTitle, setProductTitle] = useState('')
    const [productTitleTmp, setProductTitleTmp] = useState('')
    const [products, setProducts] = useState<IProduct[]>([])
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

    const show = useSelector((state: RootState) => state.product.showProductFinder)

    const args = productTitle?.length > 1 ? { q: productTitle.trim().toLowerCase() } : skipToken
    const productQuery = useListProductsQuery(args)
    const recentProductsQuery = useGetRecentProductsQuery()

    const initState = {
        title: '',
    }

    const [initialValues] = React.useState(initState)

    const {
        register,
        setFocus,
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        defaultValues: initialValues
    })

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                setFocus('title')
            }, 1000)
        }
    }, [show, setFocus])

    useEffect(() => {
        if (!productQuery.isLoading) {
            if (!productQuery.data) {
                setProducts([])
            } else {
                setProducts([...productQuery.data])
            }
        }
    }, [productQuery.data, productQuery.isLoading])

    useEffect(() => {
        if (!productTitle || productTitle.length < 2) {
            setProducts([])
        }
    }, [productTitle])

    useEffect(() => {
        if (null !== timer) {
            clearTimeout(timer)
            setTimer(timer)
        }
        const t = setTimeout(() => {
            setProductTitle(productTitleTmp)
        }, FTIMEOUT)
        setTimer(t)
    }, [productTitleTmp])

    const onChangeTitle: React.ChangeEventHandler<HTMLInputElement> | undefined = (e) => {
        setProductTitleTmp(e.target.value)
    }

    const handleClose = () => {
        setProductTitleTmp('')
        setProductTitle('')
        if (null !== timer) {
            clearTimeout(timer)
            setTimer(timer)
        }
        dispatch(showProductFinder(false))
    }

    const onSelect = (product: IProduct) => {
        setProductTitleTmp('')
        setProductTitle('')
        onProductSelect(product)
    }

    return (
        <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t('findProductTitle')}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="title">
                        <Form.Control
                            type="text"
                            placeholder={t('productNameInputPlaceholder')}
                            {...register("title")}
                            onChange={onChangeTitle}
                        />
                    </Form.Group>
                </Form>

                <ListGroup>
                    {productTitle ? <ListGroup.Item
                        onClick={() => onSelect({
                            _id: "",
                            userId: "",
                            title: productTitle,
                        })}
                    >{productTitle}</ListGroup.Item> : null}
                    {products.map(product => <ProductItem
                        key={product._id}
                        product={product}
                        onSelect={onSelect}
                    />)}
                    {recentProductsQuery.data?.length ? <div className="fw-bold">{t('recentProductsTitle')}</div> : null}
                    {recentProductsQuery.data?.map(product => <RecentProductItem
                        key={product._id}
                        product={product}
                        onSelect={onSelect}
                    />)}
                </ListGroup>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default ProductFinder

function ProductItem({ product, onSelect }: { product: IProduct, onSelect: (product: IProduct) => void }) {

    const args = product.storeId ? { storeId: product.storeId } : skipToken
    const storeQuery = useGetStoreQuery(args)

    return (
        <ListGroup.Item
            key={product._id}
            onClick={() => onSelect(product)}
        >
            <Row>
                {product.imageUrls?.length ? <Col xs="auto"><img src={product.imageUrls[0]} alt={product.title} width="100" height="75" /></Col> : null}
                <Col>
                    <Row>
                        <Col className="fw-bold">{product.title}</Col>
                    </Row>
                    {storeQuery.data ? <Row>
                        <Col className="small">
                            <Row>
                                <Col>{storeQuery.data.name}</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className="d-inline-block text-truncate" style={{ maxWidth: 150 }}>
                                        {storeQuery.data.address}
                                    </span>
                                </Col>

                            </Row>
                        </Col>
                    </Row> : null}
                </Col>
            </Row>
        </ListGroup.Item>
    )
}

function RecentProductItem({ product, onSelect }: { product: IProduct, onSelect: (product: IProduct) => void }) {

    const args = product.storeId ? { storeId: product.storeId } : skipToken
    const storeQuery = useGetStoreQuery(args)

    return (
        <ListGroup.Item
            key={product._id}
            onClick={() => onSelect(product)}
        >
            <Row>
                {product.imageUrls?.length ? <Col xs="auto"><img src={product.imageUrls[0]} alt={product.title} width="100" height="75" /></Col> : null}
                <Col>
                    <Row>
                        <Col className="fw-bold">{product.title}</Col>
                    </Row>
                    {storeQuery.data ? <Row>
                        <Col className="small">
                            <Row>
                                <Col>{storeQuery.data.name}</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className="d-inline-block text-truncate" style={{ maxWidth: 150 }}>
                                        {storeQuery.data.address}
                                    </span>
                                </Col>

                            </Row>
                        </Col>
                    </Row> : null}
                </Col>
            </Row>
        </ListGroup.Item>
    )
}