import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"
import { useGetEventQuery } from "../../Store/Event/Event.service"
import EditShoppingListPage from "./Edit/index"
import { useGetShoppingListQuery } from "../../Store/ShoppingList/ShoppingList.service"
import { useTranslation } from "react-i18next"

function EditShoppingList() {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { eventId, listId } = useParams()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const backUrl = searchParams.has('r') ? searchParams.get('r') : null

    const args = eventId ? { eventId } : skipToken
    const eventQuery = useGetEventQuery(args)

    const listArgs = eventId && listId ? { eventId, listId } : skipToken
    const listQuery = useGetShoppingListQuery(listArgs)

    const event = eventQuery.data ?? null
    const list = listQuery.data ?? null

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app')}>{t('homeTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events')}>{t('eventsTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events/' + eventId)}>{eventQuery.data?.title}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events/' + eventId + '/lists/' + listId)}>{listQuery.data?.title}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{t('editButtonLabel')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>{t('editShoppingListTitle')}</h3>
                </Col>
            </Row>
            {event && list ? <Row>
                <Col>
                    <EditShoppingListPage event={event} list={list} />
                    <div className="text-center">
                        <Button
                            variant="link"
                            onClick={() => navigate(backUrl ?? `/app/events/${eventId}/lists/${listId}`)}
                        >
                            {t('backButtonLabel')}
                        </Button>
                    </div>
                </Col>
            </Row> : null}
        </Container>
    )
}

export default EditShoppingList