import { IEvent } from "../../../Types"
import { Button, Card, ListGroup } from "react-bootstrap"
import EditText from "../../EditResource/EditText"
import * as Icon from 'react-bootstrap-icons'
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../../Store/Store"
import { useAddEventInvitationMutation, useDeleteEventInvitationMutation, useDeleteEventMutation, useUpdateEventMutation } from "../../../Store/Event/Event.service"
import { setMessage } from "../../../Store/Toast/Toast.slice"
import { ApiError } from "../../../Services/BaseApi"
import EditTextarea from "../../EditResource/EditTextarea"
import EditMembers from "../../EditResource/EditMembers"
import EditImage from "../../EditResource/EditImage"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

function EditEventPage({ event }: { event: IEvent }) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const [updateEvent, updateEventResult] = useUpdateEventMutation()
    const [deleteEvent, deleteEventResult] = useDeleteEventMutation()

    const [addEventInvitation] = useAddEventInvitationMutation()
    const [deleteEventInvitation] = useDeleteEventInvitationMutation()

    const handleUpdate = async (value: string | number, key: "title" | "description" | "imageUrl") => {
        if (!event) {
            return
        }
        try {
            const q: { [index: string]: string | number } = {}
            q[key] = value
            await updateEvent({ eventId: event._id, ...q }).unwrap()
            dispatch(setMessage(t('updateEventNotification')))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const unshare = async (email: string) => {
        if (!email) {
            return
        }
        try {
            await deleteEventInvitation({ eventId: event._id, email }).unwrap()
            dispatch(setMessage(t('invitationRemoveNotification')))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const invite = async (email: string, userId?: string) => {
        if (!email) {
            return
        }
        try {
            await addEventInvitation({ eventId: event._id, email, userId }).unwrap()
            dispatch(setMessage(t('invitationSentNotification')))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const onDelete = async () => {
        if (deleteEventResult.isLoading) {
            return
        }
        try {
            const confirm = window.confirm(`Delete event "${event.title}"?`)
            if (confirm) {
                await deleteEvent({ eventId: event._id }).unwrap()
                dispatch(setMessage(t('deleteEventNotification')))
                navigate(`/app/events`)
            }
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const shareMobile = async (token: string) => {
        if (navigator.share) {
            await navigator.share({
                title: event.title,
                text: `I invite you to join shopping event.`,
                url: `/app/event-invitations/${token}`
            })
        }
    }

    return (
        <Card>
            <Card.Img src={event.imageUrl} />
            <ListGroup>
                <ListGroup.Item>
                    <EditText
                        icon={<Icon.Fonts />}
                        disabled={updateEventResult.isLoading}
                        defaultValue={event.title}
                        placeholder={t('eventTitlePlaceholder')}
                        handleUpdate={text => handleUpdate(text, "title")}
                    />
                </ListGroup.Item>
                <ListGroup.Item>
                    <EditImage
                        icon={<Icon.Image />}
                        disabled={updateEventResult.isLoading}
                        defaultValue={event.imageUrl}
                        placeholder={t('uploadImagePlaceholder')}
                        handleUpdate={imageUrl => handleUpdate(imageUrl, "imageUrl")}
                    />
                </ListGroup.Item>
                <ListGroup.Item>
                    <EditTextarea
                        icon={<Icon.CardText />}
                        disabled={updateEventResult.isLoading}
                        defaultValue={event.description}
                        placeholder={t('addDescriptionPlaceholder')}
                        handleUpdate={text => handleUpdate(text, "description")}
                    />
                </ListGroup.Item>
                <ListGroup.Item>
                    <EditMembers
                        defaultValue={event.members}
                        unshare={unshare}
                        invite={invite}
                        shareMobile={shareMobile}
                    />
                </ListGroup.Item>
            </ListGroup>

            <div className="d-grid gap-2 mt-3">
                <Button
                    type='submit'
                    variant="danger"
                    disabled={deleteEventResult.isLoading}
                    onClick={onDelete}
                ><Icon.Trash /> {t('deleteButtonLabel')}</Button>
            </div>
        </Card>
    )
}

export default EditEventPage