export const countryCodes = ["AD", "AE", "AF", "AG", "AI", "AL", "AM", "AO", "AQ", "AR", "AS", "AT", "AU", "AW", "AX", "AZ", "BA", "BB", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BL", "BM", "BN", "BO", "BQ", "BR", "BS", "BT", "BV", "BW", "BY", "BZ", "CA", "CC", "CD", "CF", "CG", "CH", "CI", "CK", "CL", "CM", "CN", "CO", "CR", "CU", "CV", "CW", "CX", "CY", "CZ", "DE", "DJ", "DK", "DM", "DO", "DZ", "EC", "EE", "EG", "EH", "ER", "ES", "ET", "FI", "FJ", "FK", "FM", "FO", "FR", "GA", "GB", "GD", "GE", "GF", "GG", "GH", "GI", "GL", "GM", "GN", "GP", "GQ", "GR", "GS", "GT", "GU", "GW", "GY", "HK", "HM", "HN", "HR", "HT", "HU", "ID", "IE", "IL", "IM", "IN", "IO", "IQ", "IR", "IS", "IT", "JE", "JM", "JO", "JP", "KE", "KG", "KH", "KI", "KM", "KN", "KP", "KR", "KW", "KY", "KZ", "LA", "LB", "LC", "LI", "LK", "LR", "LS", "LT", "LU", "LV", "LY", "MA", "MC", "MD", "ME", "MF", "MG", "MH", "MK", "ML", "MM", "MN", "MO", "MP", "MQ", "MR", "MS", "MT", "MU", "MV", "MW", "MX", "MY", "MZ", "NA", "NC", "NE", "NF", "NG", "NI", "NL", "NO", "NP", "NR", "NU", "NZ", "OM", "PA", "PE", "PF", "PG", "PH", "PK", "PL", "PM", "PN", "PR", "PS", "PT", "PW", "PY", "QA", "RE", "RO", "RS", "RU", "RW", "SA", "SB", "SC", "SD", "SE", "SG", "SH", "SI", "SJ", "SK", "SL", "SM", "SN", "SO", "SR", "SS", "ST", "SV", "SX", "SY", "SZ", "TC", "TD", "TF", "TG", "TH", "TJ", "TK", "TL", "TM", "TN", "TO", "TR", "TT", "TV", "TW", "TZ", "UA", "UG", "UM", "US", "UY", "UZ", "VA", "VC", "VE", "VG", "VI", "VN", "VU", "WF", "WS", "XK", "YE", "YT", "ZA", "ZM", "ZW"]
export type countryCodesType = typeof countryCodes[number]

export const currencyCodes = ["USD", "CAD", "EUR", "AED", "AFN", "ALL", "AMD", "ARS", "AUD", "AZN", "BAM", "BDT", "BGN", "BHD", "BIF", "BND", "BOB", "BRL", "BWP", "BYN", "BZD", "CDF", "CHF", "CLP", "CNY", "COP", "CRC", "CVE", "CZK", "DJF", "DKK", "DOP", "DZD", "EEK", "EGP", "ERN", "ETB", "GBP", "GEL", "GHS", "GNF", "GTQ", "HKD", "HNL", "HRK", "HUF", "IDR", "ILS", "INR", "IQD", "IRR", "ISK", "JMD", "JOD", "JPY", "KES", "KHR", "KMF", "KRW", "KWD", "KZT", "LBP", "LKR", "LTL", "LVL", "LYD", "MAD", "MDL", "MGA", "MKD", "MMK", "MOP", "MUR", "MXN", "MYR", "MZN", "NAD", "NGN", "NIO", "NOK", "NPR", "NZD", "OMR", "PAB", "PEN", "PHP", "PKR", "PLN", "PYG", "QAR", "RON", "RSD", "RUB", "RWF", "SAR", "SDG", "SEK", "SGD", "SOS", "SYP", "THB", "TND", "TOP", "TRY", "TTD", "TWD", "TZS", "UAH", "UGX", "UYU", "UZS", "VEF", "VND", "XAF", "XOF", "YER", "ZAR", "ZMK", "ZWL"] as const
export type currencyCodesType = typeof currencyCodes[number]

export const langCodes = ["en", "he", "ru"] as const
export type langCodesType = typeof langCodes[number]

export interface IMember {
    userId?: string
    name?: string
    email: string
    picture?: string
    token: string
    status: "PENDING" | "ACCEPTED"
    tokenExpiry: Date
    invitedAt: Date
    acceptedAt?: Date
}

export interface IEvent {
    _id: string
    userId: string
    members?: IMember[]
    title: string
    imageUrl?: string
    description?: string
    createdAt?: Date
}

export interface IReceiptRecord {
    label: string
    qty?: number
    amount: number
}

export interface IReceipt {
    amount: number
    currencyCode: currencyCodesType
    langCode?: string
    records?: IReceiptRecord[]
    imageUrl?: string
}

export interface IShoppingList {
    _id: string
    userId: string
    members?: IMember[]
    assigneeUserId?: string
    eventId: string
    title: string
    isComplete?: boolean
    imageUrl?: string
    description?: string
    scheduleAt?: Date
    receipts?: IReceipt[]
    createdAt?: Date
}

export interface IListProduct {
    _id: string
    userId: string
    eventId: string
    listId: string
    title: string
    status: "PENDING" | "COMPLETE"
    description?: string
    imageUrls?: string[]
    storeId?: string
    qty?: number
    unitPrice?: number
    weightGr?: number
    sku?: string
    currency?: currencyCodesType
    createdAt?: Date
}

export interface IProduct {
    _id: string
    userId: string
    title: string
    description?: string
    imageUrls?: string[]
    storeId?: string
    unitPrice?: number
    weightGr?: number
    sku?: string
    currency?: currencyCodesType
    createdAt?: Date
}

export interface IStore {
    _id: string
    userId: string
    name: string
    description?: string
    address?: string
    createdAt?: Date
}

export interface IUserConfig {
    _id: string
    userId: string
    countryCode: countryCodesType
    currencyCode: currencyCodesType
    langCode: langCodesType
    createdAt?: Date
}

export type Currency = {
    symbol: string
    name?: string
    symbol_native?: string
    decimal_digits?: number
    rounding?: number
    code: currencyCodesType
    name_plural?: string
}

export type Currencies = { [key in currencyCodesType]: Currency }

export type Country = {
    countryCode: string
    countryName: string
    currencyCode: string
    population: string
    capital: string
    continentName: string
}

export interface IEventInvitation {
    _id: string
    userId: string
    email: string
    eventId: string
    token?: string
    status: "PENDING" | "ACCEPTED"
    tokenExpiry?: Date
    createdAt?: Date
}

export interface IUser {
    userId: string
    given_name: string
    family_name: string
    name: string
    nickname: string
    picture: string
    email: string
}

export interface IShoppingListInvitation {
    _id: string
    userId: string
    email: string
    eventId: string
    listId: string
    acceptingUserId?: string
    token?: string
    status: "PENDING" | "ACCEPTED"
    tokenExpiry?: Date
    acceptedAt?: Date
    createdAt?: Date
}

export type InvitationUser = {
    user?: IUser
    invitation?: {
        _id: string
        email: string
        acceptingUserId?: string
        status: "PENDING" | "ACCEPTED"
        acceptedAt?: Date
        createdAt?: Date
    }
}

export type InvitationUsers = InvitationUser[]

export type ValuePiece = Date | null;

export type Value = ValuePiece | [ValuePiece, ValuePiece];

export type SharedList = (IShoppingList & { invitation: IShoppingListInvitation })
export type SharedLists = SharedList[]
export type SharedListsEvent = (IEvent & { lists: SharedLists })
export type SharedListsEvents = SharedListsEvent[]

export type Member = {
    userId: string
    name: string
    email: string
    picture?: string
    isMe: boolean
}

export interface INotification {
    _id: string
    userId: string
    title: string
    text: string
    url?: string
    isRead?: boolean
    createdAt?: Date
}