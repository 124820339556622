import { skipToken } from "@reduxjs/toolkit/query"
import { Breadcrumb, Button, Card, Col, Container, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useGetEventQuery } from "../../Store/Event/Event.service"
import * as Icon from 'react-bootstrap-icons'
import EventUserAvatars from "../EventUserAvatars"
import ShoppingListItem from "../ShoppingListItem"
import { useMemo, useState } from "react"
import { currencyCodesType, IEvent, IShoppingList, Member } from "../../Types"
import TitleButton from "../TitleButton"
import { useListShoppingListsQuery } from "../../Store/ShoppingList/ShoppingList.service"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import { CurrencyService } from "../../Services/Currency"
import { useAuth0 } from "@auth0/auth0-react"
import UserName from "../UserName"
import Loading from "../Loading"

function Event() {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { eventId } = useParams()

    const args = eventId ? { eventId } : skipToken
    const eventQuery = useGetEventQuery(args)

    const listArgs = eventId ?? skipToken
    const listsQuery = useListShoppingListsQuery(listArgs)

    const members = useMemo(() => {
        if (eventQuery.isLoading || !eventQuery.isSuccess || !eventQuery.data?.members?.length) {
            return []
        }
        const event = eventQuery.data
        const members = event.members?.filter(member => !!member.userId).map(member => {
            return {
                userId: member.userId,
                email: member.email,
                name: member.name,
                picture: member.picture,
                isMe: false,
            }
        }) as Member[]

        return members
    }, [eventQuery.isLoading, eventQuery.isSuccess, eventQuery.data])

    return (
        <Container>
            <Breadcrumbs
                title={eventQuery.data?.title}
            />
            <EventTitle
                title={eventQuery.data?.title}
                eventUserId={eventQuery.data?.userId}
                eventId={eventId}
            />
            <EventMembers
                event={eventQuery.data}
                lists={listsQuery.data}
            />
            <EventDescription
                description={eventQuery.data?.description}
            />
            <Row className="mb-3">
                <Col>
                    <TitleButton
                        title={t('shoppingListsTitle')}
                        buttonText={<><Icon.Plus /> {t('addShoppingListButtonLabel')}</>}
                        onClick={() => navigate(`/app/events/${eventId}/lists-add`)}
                    />
                </Col>
            </Row>
            <ShoppingLists
                event={eventQuery.data}
                lists={listsQuery.data}
                isLoading={eventQuery.isLoading || listsQuery.isLoading}
            />
        </Container>
    )
}

export default Event

function Breadcrumbs({
    title,
}: {
    title?: string
}) {

    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <Row>
            <Col>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate('/app')}>{t('homeTitle')}</Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => navigate('/app/events')}>{t('eventsTitle')}</Breadcrumb.Item>
                    <Breadcrumb.Item active>{title}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
        </Row>
    )
}

function EventTitle({
    title,
    eventId,
    eventUserId,
}: {
    title?: string
    eventId?: string
    eventUserId?: string
}) {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const { user } = useAuth0()

    let view = null
    if (title && eventId) {
        view = <Row className="mb-3">
            <Col>
                <TitleButton
                    title={title}
                    buttonText={user?.sub === eventUserId ? <><Icon.Pencil /> {t('editButtonLabel')}</> : undefined}
                    onClick={user?.sub === eventUserId ? () => navigate(`/app/events-edit/${eventId}?r=/app/events/${eventId}`) : undefined}
                />
            </Col>
        </Row>
    }

    return (
        view
    )
}

function EventMembers({
    lists,
    event,
}: {
    lists?: IShoppingList[]
    event?: IEvent
}) {

    const { t } = useTranslation()

    const members = useMemo(() => {
        if (!event?.members?.length) {
            return []
        }
        const members = event.members?.filter(member => !!member.userId).map(member => {
            return {
                userId: member.userId,
                email: member.email,
                name: member.name,
                picture: member.picture,
                isMe: false,
            }
        }) as Member[]

        return members
    }, [event])

    let view = null
    if (members.length) {
        view = <Row className="mb-1">
            <Col xs="auto">
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={props => (
                        <Tooltip id="button-tooltip" {...props}>
                            {t('membersTitle')}
                        </Tooltip>
                    )}
                >
                    <Icon.People />
                </OverlayTrigger>
            </Col>
            <Col><EventUserAvatars users={members.map(member => member)} /></Col>
            {lists?.length ? <Col xs="auto"><Split lists={lists} /></Col> : null}
        </Row>
    }

    return (
        view
    )
}

function EventDescription({
    description,
}: {
    description?: string
}) {
    let view = null
    if (description) {
        view = <Row className="mb-3">
            <Col className="small">
                {description}
            </Col>
        </Row>
    }

    return (
        view
    )
}

function Split({
    lists,
}: {
    lists: IShoppingList[]
}) {

    const { t } = useTranslation()
    const { user } = useAuth0();
    const [show, setShow] = useState(false)

    const totals = useMemo(() => {
        const table: {
            userId: string
            currencies: {
                [index: string]: number;
            }
        }[] = []
        const totals: {
            [index: string]: {
                [index: string]: number
            }
        } = {}
        if (lists.length) {
            for (const list of lists) {
                if (list.receipts?.length) {
                    const userId = list.assigneeUserId ?? list.userId
                    for (const receipt of list.receipts) {
                        if (!totals[userId]) {
                            totals[userId] = {}
                        }
                        if (!totals[userId][receipt.currencyCode]) {
                            totals[userId][receipt.currencyCode] = 0
                        }
                        totals[userId][receipt.currencyCode] += receipt.amount
                    }
                }
            }
            const userIds = Object.keys(totals)
            if (userIds.length) {
                for (const userId of userIds) {
                    table.push({ userId, currencies: totals[userId] })
                }
            }
        }

        return table
    }, [
        lists,
    ])

    const onHide = () => {
        setShow(false)
    }

    return (
        <>
            <Button
                variant="link"
                size="sm"
                onClick={() => setShow(true)}
            >{t('splitTitle')}</Button>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('eventSplitTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {totals.length ? totals.map((total, idx) => <Row key={idx}>
                        <Col>{user && total.userId === user.sub ? 'You' : <UserName userId={total.userId} />}</Col>
                        <Col xs="auto">{Object.keys(total.currencies).map(currencyCode => {
                            const c = CurrencyService.getCurrency(currencyCode as currencyCodesType)
                            let symbol = '$'
                            if (c) {
                                symbol = c.symbol
                            }
                            return (
                                <NumericFormat
                                    key={idx}
                                    value={total.currencies[currencyCode]}
                                    displayType="text"
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={symbol}
                                />
                            )
                        })}</Col>
                    </Row>) : <Row>
                        <Col>
                            {t('receiptsNotFoundText')}
                        </Col>
                    </Row>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        {t('closeLabel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function ShoppingLists({
    event,
    lists,
    isLoading,
}: {
    event?: IEvent
    lists?: IShoppingList[]
    isLoading: boolean
}) {

    const { t } = useTranslation()
    const navigate = useNavigate()

    let view = <Row>
        <Col className="text-center">
            <Loading />
        </Col>
    </Row>
    if (!isLoading) {
        view = <Row>
            <Col>
                <Card>
                    <Card.Body className="text-center">
                        <Card.Text>{t('addFirstList')}</Card.Text>
                        <Button
                            variant="primary"
                            onClick={() => navigate(`/app/events/${event?._id}/lists-add`)}
                        ><Icon.Plus /> {t('addShoppingListButtonLabel')}</Button>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        if (lists?.length && event) {
            view = <Row>
                <Col>
                    {lists.map(list => <ShoppingListItem
                        key={list._id}
                        event={event}
                        list={list}
                        isShared={false}
                        canEdit={true}
                    />)}
                </Col>
            </Row>
        }
    }

    return (
        view
    )
}