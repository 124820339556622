import React from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

export type AddEventFormData = {
    title: string
}

interface IAddEventFormProps {
    handleSubmit(data: AddEventFormData): void
    disabled: boolean
}

const AddEventForm: React.FC<IAddEventFormProps> = (props: IAddEventFormProps) => {

    const { t } = useTranslation()
    
    const initState = {
        title: "",
    }

    const [initialValues] = React.useState(initState)

    const onSubmit = (values: AddEventFormData) => {
        props.handleSubmit(values)
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        defaultValues: initialValues
    })

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="title">
                <Form.Label>{t('titleLabel')}</Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t('eventTitleValuePlaceholder')}
                    {...register("title", { required: t('titleInputRequiredError') })}
                />
                {errors.title && (
                    <Form.Text className="text-danger">
                        {errors.title.message}
                    </Form.Text>
                )}
            </Form.Group>

            <div className="d-grid gap-2">
                <Button
                    type='submit'
                    variant="primary"
                    disabled={!isValid || props.disabled}
                >{t('addButtonLabel')}</Button>
            </div>
        </Form>

    )
}

export default AddEventForm