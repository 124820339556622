import { Button, Card, Carousel, ListGroup, Offcanvas } from "react-bootstrap"
import { Currency, currencyCodes, currencyCodesType, IListProduct, IShoppingList, IStore } from "../../../Types"
import EditText from "../../EditResource/EditText"
import * as Icon from 'react-bootstrap-icons'
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../../Store/Store"
import { setMessage } from "../../../Store/Toast/Toast.slice"
import { ApiError } from "../../../Services/BaseApi"
import EditTextarea from "../../EditResource/EditTextarea"
import EditNumber from "../../EditResource/EditNumber"
import { CurrencyService } from "../../../Services/Currency"
import EditList from "../../EditResource/EditList"
import { useMemo } from "react"
import { useUpdateListProductMutation, useDeleteListProductMutation, useDeleteListProductImageMutation, useMoveListProductMutation } from "../../../Store/ListProduct/ListProduct.service"
import { useListStoresQuery } from "../../../Store/Store/Store.service"
import EditImages from "../../EditResource/EditImages"
import { useTranslation } from "react-i18next"
import { useListShoppingListsQuery } from "../../../Store/ShoppingList/ShoppingList.service"
import EditBarcode from "../../EditResource/EditBarcode"

function EditProduct({ show, handleClose, product }: { show: boolean, handleClose: () => void, product: IListProduct }) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [updateProduct, updateProductResult] = useUpdateListProductMutation()
    const [deleteProduct, deleteProductResult] = useDeleteListProductMutation()
    const [deleteListProductImage, deleteListProductImageResult] = useDeleteListProductImageMutation()
    const [moveListProduct, moveListProductResult] = useMoveListProductMutation()

    const query = useListStoresQuery('')
    const listsQuery = useListShoppingListsQuery(product.eventId)

    const listsIndex = useMemo(() => {
        const listsIndex: { [index: string]: string } = {}
        if (!listsQuery.isLoading && listsQuery.isSuccess && listsQuery.data?.length) {
            for (const list of listsQuery.data) {
                listsIndex[list._id] = list.title
            }
        }
        return listsIndex
    }, [
        listsQuery.isLoading,
        listsQuery.isSuccess,
        listsQuery.data,
    ])

    const currencies = useMemo(() => {
        const currencies: Currency[] = []
        for (const code of currencyCodes) {
            const currency = CurrencyService.getCurrency(code)
            if (currency) {
                currencies.push(currency)
            }
        }
        return currencies
    }, [])

    const handleUpdate = async (
        value: string | number,
        key: "title" | "description" | "unitPrice" | "weightGr" | "currency" | "storeId" | "qty" | "imageUrl" | "sku") => {
        if (!product) {
            return
        }
        try {
            const q: { [index: string]: string | number } = {}
            q[key] = value
            await updateProduct({ eventId: product.eventId, listId: product.listId, productId: product._id, ...q }).unwrap()
            dispatch(setMessage(t('productUpdatedNotification')))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const getDisplayLabelStore = (storeId: string) => {
        if (!query.data) {
            return ''
        }
        let store: IStore | null = null
        for (const storeItem of query.data) {
            if (storeItem._id === storeId) {
                store = storeItem
                break
            }
        }
        if (store) {
            return <>
                <div>{store.name}</div>
                <div className="small">{store.address}</div>
                <div className="small">{store.description}</div>
            </>
        }
        return ''
    }

    const getDisplayItemStore = (store: IStore) => <>
        <div>{store.name}</div>
        <div className="small">{store.address}</div>
        <div className="small">{store.description}</div>
    </>

    const getDisplayLabelCurrency = (code: currencyCodesType) => {
        const currency = CurrencyService.getCurrency(code)
        if (currency) {
            return <>
                {currency.name} {currency.symbol}
            </>
        }
        return ''
    }

    const getDisplayItemCurrency = (currency: Currency) => {
        if (!currency) {
            return null
        }
        return (
            <>
                <div>{currency.symbol}</div>
                <div className="small">{currency.name}</div>
            </>
        )
    }

    const onFilterCurrency = (search: string, objects: Currency[]) => {
        return objects.filter(currency => {
            if (currency.code.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            if (currency.name && currency.name.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            return false
        })
    }

    const onFilterStore = (search: string, objects: IStore[]) => {
        return objects.filter(store => {
            if (store.name.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            if (store.description && store.description.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            if (store.address && store.address.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            return false
        })
    }

    const onDelete = async () => {
        const confirm = window.confirm(`Delete product ${product.title}?`)
        if (confirm) {
            if (!product) {
                return
            }
            try {
                await deleteProduct({
                    eventId: product.eventId,
                    listId: product.listId,
                    productId: product._id,
                }).unwrap()
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }
    }

    const onDeleteImage = async (imageIndex: number) => {
        const confirm = window.confirm(`Delete product image?`)
        if (confirm) {
            try {
                await deleteListProductImage({
                    eventId: product.eventId,
                    listId: product.listId,
                    productId: product._id,
                    imageIndex,
                }).unwrap()
                dispatch(setMessage(t('imageRemovedNotification')))
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }
    }

    const currencyIcon = useMemo(() => {
        let icon = <Icon.Icon123 />
        const symbol = CurrencyService.getCurrency(product.currency as currencyCodesType)?.symbol
        if (symbol) {
            icon = <>{symbol}</>
        }
        return icon
    }, [product.currency])

    const getDisplayLabel = (listId: string) => {
        let title = ''
        if (listsIndex[listId]) {
            title = listsIndex[listId]
        }
        return title
    }

    const getDisplayItem = (list: IShoppingList) => {
        return (
            <>{list.title}</>
        )
    }

    const handleMove = async (listId: string) => {
        if (!product) {
            return
        }
        try {
            await moveListProduct({
                eventId: product.eventId,
                listId: product.listId,
                productId: product._id,
                targetListId: listId,
            }).unwrap()
            handleClose()
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    return (
        <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t('editProductTitle')}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Card>
                    {product.imageUrls?.length ? <Carousel>
                        {product.imageUrls.map((imageUrl, idx) => <Carousel.Item key={idx}>
                            <Card.Img src={imageUrl} />
                        </Carousel.Item>)}
                    </Carousel> : null}
                    <ListGroup>
                        <ListGroup.Item>
                            <EditText
                                icon={<Icon.Fonts />}
                                disabled={updateProductResult.isLoading}
                                defaultValue={product.title}
                                placeholder={t('productNameInputPlaceholder')}
                                handleUpdate={text => handleUpdate(text, "title")}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <EditBarcode
                                icon={<Icon.UpcScan />}
                                disabled={updateProductResult.isLoading}
                                defaultValue={product.sku}
                                placeholder={t('addBarcodePlaceholder')}
                                handleUpdate={sku => handleUpdate(sku, "sku")}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <EditNumber
                                icon={<Icon.Icon123 />}
                                disabled={updateProductResult.isLoading}
                                defaultValue={product.qty}
                                placeholder={t('addQtyInputPlaceholder')}
                                handleUpdate={text => handleUpdate(text, "qty")}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <EditImages
                                icon={<Icon.Image />}
                                disabled={updateProductResult.isLoading}
                                imageUrls={product.imageUrls}
                                placeholder={t('uploadImagePlaceholder')}
                                handleUpdate={imageUrl => handleUpdate(imageUrl, "imageUrl")}
                                isLoading={deleteListProductImageResult.isLoading}
                                onDeleteImage={onDeleteImage}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <EditTextarea
                                icon={<Icon.CardText />}
                                disabled={updateProductResult.isLoading}
                                defaultValue={product.description}
                                placeholder={t('addDescriptionPlaceholder')}
                                handleUpdate={text => handleUpdate(text, "description")}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <EditList<Currency>
                                icon={<Icon.Cash />}
                                disabled={updateProductResult.isLoading}
                                defaultValue={product.currency}
                                placeholder={t('addCurrencyInputPlaceholder')}
                                handleUpdate={code => handleUpdate(code, "currency")}
                                objects={currencies}
                                primKey="code"
                                title={t('selectCurrencyInputPlaceholder')}
                                isMandatory={false}
                                getDisplayLabel={code => getDisplayLabelCurrency(code as currencyCodesType)}
                                getDisplayItem={getDisplayItemCurrency}
                                onFilter={onFilterCurrency}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <EditNumber
                                icon={currencyIcon}
                                disabled={updateProductResult.isLoading}
                                defaultValue={product.unitPrice}
                                placeholder={t('addUnitPriceInputPlaceholder')}
                                handleUpdate={num => handleUpdate(num, "unitPrice")}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <EditNumber
                                icon={<Icon.Stack />}
                                disabled={updateProductResult.isLoading}
                                defaultValue={product.weightGr}
                                placeholder={t('addWeightInputPlaceholder')}
                                handleUpdate={num => handleUpdate(num, "weightGr")}
                                right={<>gr</>}
                            />
                        </ListGroup.Item>
                        {query.data ? <ListGroup.Item>
                            <EditList<IStore>
                                icon={<Icon.Shop />}
                                disabled={updateProductResult.isLoading}
                                defaultValue={product.storeId}
                                placeholder={t('addStoreInputPlaceholder')}
                                handleUpdate={storeId => handleUpdate(storeId, "storeId")}
                                objects={query.data}
                                primKey="_id"
                                title={t('selectStoreInputPlaceholder')}
                                isMandatory={false}
                                getDisplayLabel={getDisplayLabelStore}
                                getDisplayItem={getDisplayItemStore}
                                onFilter={onFilterStore}
                            />
                        </ListGroup.Item> : null}
                        {listsQuery.data?.length ? <ListGroup.Item>
                            <EditList<IShoppingList>
                                icon={<Icon.List />}
                                disabled={moveListProductResult.isLoading}
                                defaultValue={product.listId}
                                placeholder={t('selectShoppingListLabel')}
                                handleUpdate={_id => handleMove(_id)}
                                objects={listsQuery.data}
                                primKey="_id"
                                title={t('selectShoppingListLabel')}
                                isMandatory={true}
                                getDisplayLabel={getDisplayLabel}
                                getDisplayItem={getDisplayItem}
                            />
                        </ListGroup.Item> : null}
                    </ListGroup>

                    <div className="d-grid gap-2 mt-3">
                        <Button
                            type='submit'
                            variant="danger"
                            disabled={deleteProductResult.isLoading}
                            onClick={onDelete}
                        ><Icon.Trash /> {t('deleteButtonLabel')}</Button>
                    </div>
                </Card>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default EditProduct