import { baseSplitApi } from "../../Services/BaseApi"
import { InvitationUsers, IUser, IUserConfig } from "../../Types"
import { UserConfigPayload } from "./Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        getUserConfig: build.query<IUserConfig & IUser, void>({
            query: () => '/user-config',
            providesTags: ["UserConfig"],
        }),
        setUserConfig: build.mutation<void, UserConfigPayload>({
            query: (payload) => ({
                url: '/user-config',
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ["UserConfig"],
        }),
        setUserWebPush: build.mutation<void, string>({
            query: (token) => ({
                url: '/user-web-pushes',
                method: 'POST',
                body: {
                    token,
                },
            }),
        }),
        getUser: build.query<IUser, string>({
            query: (userId) => '/users/' + userId,
            providesTags: ["Users"],
        }),
        listUsers: build.query<InvitationUsers, string>({
            query: (listId) => '/list-users/' + listId,
            providesTags: ["InvitationUsers"]
        }),
        listInvitedUsers: build.query<IUser[], void>({
            query: () => '/invited-users',
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetUserConfigQuery,
    useSetUserConfigMutation,
    useSetUserWebPushMutation,
    useGetUserQuery,
    useListInvitedUsersQuery,
} = api