import { Button, Card, Carousel, Col, Container, Form, Modal, Row } from "react-bootstrap"
import { NumericFormat } from "react-number-format"
import { CurrencyService } from "../../Services/Currency"
import { useMemo, useState } from "react"
import * as Icon from 'react-bootstrap-icons'
import { IProduct } from "../../Types"
import { useAddProductMutation, useDeleteProductMutation, useListProductsQuery } from "../../Store/Product/Product.service"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { ApiError } from "../../Services/BaseApi"
import { useGetStoreQuery } from "../../Store/Store/Store.service"
import { skipToken } from "@reduxjs/toolkit/query"
import { useForm } from "react-hook-form"
import Loading from "../Loading"
import { useNavigate } from "react-router-dom"

function Products() {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const productsQuery = useListProductsQuery({})

    const [showAdd, setShowAdd] = useState(false)

    const onAdd = (product: IProduct) => {
        setShowAdd(false)
        navigate(`/app/products/${product._id}/edit`)
    }

    return (
        <Container>
            <Row>
                <Col><h3>{t('myProductsTitle')}</h3></Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <div className="d-grid gap-2">
                        <Button
                            variant="primary"
                            onClick={() => setShowAdd(true)}
                        >{t('addButtonLabel')}</Button>
                    </div>
                </Col>
            </Row>
            {productsQuery.data?.length ? <Row>
                <Col>
                    {productsQuery.data?.map(product => {
                        let weight = ''
                        if (product.weightGr) {
                            weight = `${product.weightGr}`
                        }

                        let symbol = '$'
                        if (product.currency) {
                            const currency = CurrencyService.getCurrency(product.currency)
                            if (currency) {
                                symbol = currency.symbol
                            }
                        }

                        return (
                            <Row key={product._id} className="mb-3">
                                <Col>
                                    <Card>
                                        {product.imageUrls?.length ? <Carousel interval={null}>
                                            {product.imageUrls.map((imageUrl, idx) => <Carousel.Item key={idx}>
                                                <Card.Img src={imageUrl} />
                                            </Carousel.Item>)}
                                        </Carousel> : null}
                                        <Card.Body>
                                            <Row>
                                                <Col>
                                                    <div className="fw-bold">{product.title}</div>
                                                    {product.storeId ? <ProductStore product={product} /> : null}
                                                    {product.description ? <div className="small mb-3">{product.description}</div> : null}
                                                </Col>
                                            </Row>
                                            <ProductAttr product={product} />
                                        </Card.Body>
                                        <Card.Footer>
                                            <Row>
                                                <Col></Col>
                                                <Col xs="auto"><Icon.Pencil onClick={() => navigate(`/app/products/${product._id}/edit`)} className="text-secondary" /></Col>
                                                <Col xs="auto"><ProductDeleteButton product={product} /></Col>
                                            </Row>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                        )
                    })}
                </Col>
            </Row> : <Row>
                <Col>
                    {t('noProductsText')}
                </Col>
            </Row>}
            <ProductAddModal
                show={showAdd}
                onHide={() => setShowAdd(false)}
                onAdd={onAdd}
            />
        </Container>
    )
}

export default Products

function ProductAttr({ product }: { product: IProduct }) {

    const { t } = useTranslation()
    const weight = useMemo(() => {
        let weight = ''
        if (product.weightGr) {
            weight = `${product.weightGr}`
        }
        return weight
    }, [product.weightGr])

    const symbol = useMemo(() => {
        let symbol = '$'
        if (product.currency) {
            const currency = CurrencyService.getCurrency(product.currency)
            if (currency) {
                symbol = currency.symbol
            }
        }
        return symbol
    }, [product.currency])

    const unitPrice = product.unitPrice

    return (
        <Row>
            {product.unitPrice ? <Col xs="auto">
                <Row>
                    <Col className="small">{t('priceLabel')}</Col>
                </Row>
                <Row>
                    <Col className="fs-4">
                        <NumericFormat
                            value={unitPrice}
                            displayType="text"
                            thousandSeparator={true}
                            decimalScale={2}
                            prefix={symbol}
                        />
                    </Col>
                </Row>
            </Col> : null}
            {weight ? <Col xs="auto">
                <Row>
                    <Col className="small">{t('weightLabel')}</Col>
                </Row>
                <Row>
                    <Col className="fs-3">
                        <NumericFormat
                            value={weight}
                            displayType="text"
                            thousandSeparator={true}
                            decimalScale={2}
                            suffix={t('grLabel')}
                        />
                    </Col>
                </Row>
            </Col> : null}
        </Row>
    )
}

function ProductDeleteButton({ product }: { product: IProduct }) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [deleteProduct, deleteProductResult] = useDeleteProductMutation()

    const onDelete = async (product: IProduct) => {
        if (deleteProductResult.isLoading) {
            return
        }
        const confirm = window.confirm(t('deleteProductFromShoppingListConfirmationText', { productTitle: product.title }))
        if (confirm) {
            try {
                await deleteProduct(product._id)
                dispatch(setMessage(t('productRemovedNotification')))
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }
    }

    return (
        <Icon.Trash
            onClick={() => onDelete(product)}
            className={deleteProductResult.isLoading ? "text-muted" : "text-danger"}
        />
    )
}

function ProductStore({ product }: { product: IProduct }) {

    const args = product.storeId ? { storeId: product.storeId, productId: product._id } : skipToken
    const storeQuery = useGetStoreQuery(args)

    return (
        <div className="small mb-3">
            <div>{storeQuery.data?.name}</div>
            {storeQuery.data?.address ? <div><a href={`https://maps.google.com/?q=${storeQuery.data.address}`} target="_blank" rel="noreferrer">{storeQuery.data.address}</a></div> : null}
        </div>
    )
}

function ProductAddModal({ show, onHide, onAdd }: { show: boolean, onHide: () => void, onAdd: (product: IProduct) => void }) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [addProduct, addProductResult] = useAddProductMutation()

    const addProductCallback = async (name: string) => {
        try {
            const product = await addProduct(name).unwrap()
            onAdd(product)
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const initState = {
        name: "",
    }

    const [initialValues] = useState(initState)

    const onSubmit = (values: { name: string }) => {
        addProductCallback(values.name)
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        defaultValues: initialValues
    })

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t('addNewProductTitle')}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Control
                            type="text"
                            placeholder={t('productNameInputPlaceholder')}
                            {...register("name", { required: t('nameInputRequiredError') })}
                        />
                        {errors.name && (
                            <Form.Text className="text-danger">
                                {errors.name.message}
                            </Form.Text>
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        {t('closeLabel')}
                    </Button>
                    <Button
                        type='submit'
                        variant="primary"
                        disabled={!isValid || addProductResult.isLoading}
                    >
                        {addProductResult.isLoading ? <Loading /> : t('addButtonLabel')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}