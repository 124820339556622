import { Col, Offcanvas, Row } from "react-bootstrap"
import { ReactNode, useState } from "react"
import ProductImagesUpload from "../../ProductImagesUpload"
import { useTranslation } from "react-i18next"

function EditImages({
    imageUrls,
    placeholder,
    icon,
    disabled,
    isLoading,
    handleUpdate,
    onDeleteImage,
}: {
    imageUrls?: string[]
    placeholder?: string
    icon: ReactNode
    disabled?: boolean
    isLoading: boolean
    handleUpdate: (imageUrl: string) => void
    onDeleteImage: (imageIndex: number) => void
}) {

    const { t } = useTranslation()
    const [edit, setEdit] = useState(false)

    const handleSave = (imageUrl: string) => {
        handleUpdate(imageUrl)
    }

    let className: string | undefined = "text-secondary"
    if (imageUrls?.length) {
        className = undefined
    }

    let view = <Row>
        <Col className={className} xs={'auto'}>{icon}</Col>
        <Col className={className} onClick={() => setEdit(true)}>{imageUrls?.length ? t('changeImagesLabel') : placeholder ? placeholder : ''}</Col>
        <Offcanvas show={edit} onHide={() => setEdit(false)}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t('uploadImagesTitle')}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ProductImagesUpload
                    disabled={disabled}
                    onChange={handleSave}
                    imageUrls={imageUrls}
                    isLoading={isLoading}
                    onDelete={onDeleteImage}
                />
            </Offcanvas.Body>
        </Offcanvas>
    </Row>

    return view
}

export default EditImages