import { Card, Col, Form, InputGroup, Offcanvas, Row } from "react-bootstrap"
import { ReactNode, useEffect, useState } from "react"
import * as Icon from 'react-bootstrap-icons'
import { useTranslation } from "react-i18next"

function EditList<T>({
    title,
    defaultValue,
    placeholder,
    isMandatory,
    icon,
    objects,
    disabled,
    handleUpdate,
    getDisplayLabel,
    getDisplayItem,
    primKey,
    onFilter,
}: {
    title: string
    defaultValue?: string
    placeholder?: string
    isMandatory?: boolean
    icon: ReactNode
    objects: T[]
    disabled?: boolean
    handleUpdate: (_id: string) => void
    getDisplayLabel: (_id: string) => ReactNode
    getDisplayItem: (obj: T, isSelected: boolean) => ReactNode
    primKey: keyof T
    onFilter?: (search: string, objects: T[]) => T[]
}) {

    const { t } = useTranslation()
    const [edit, setEdit] = useState(false)
    const [search, setSearch] = useState('')
    const [filtered, setFiltered] = useState<T[]>([...objects])

    useEffect(() => {
        if (onFilter) {
            if (search) {
                const objs = onFilter(search, objects)
                setFiltered([...objs])
            } else {
                setFiltered([...objects])
            }
        }
    }, [search, objects])

    const handleSave = (obj?: T) => {
        if (!disabled) {
            setEdit(false)
            let val = ''
            if (obj && obj[primKey]) {
                val = obj[primKey] as string
            }
            handleUpdate(val)
        }
    }

    let className: string | undefined = "text-secondary"
    if (defaultValue) {
        className = undefined
    }

    let view = <Row>
        <Col className={className} xs={'auto'}>{icon}</Col>
        <Col className={className} onClick={() => setEdit(true)}>{defaultValue ? getDisplayLabel(defaultValue) : placeholder ? placeholder : ''}</Col>
        <Offcanvas show={edit} onHide={() => setEdit(false)}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{title}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {onFilter ? <div>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder={t('searchLabel')}
                            defaultValue={search}
                            onChange={e => setSearch(e.target.value)}
                            disabled={disabled}
                        />
                        <InputGroup.Text
                            id="basic-addon2"
                        >
                            <Icon.Search />
                        </InputGroup.Text>
                    </InputGroup>
                </div> : null}
                {!isMandatory ? <Card
                    key={'nostore'}
                    onClick={() => handleSave()}
                    border={!defaultValue ? "success" : undefined}
                    className="mb-3"
                >
                    <Card.Body>
                        <Card.Text>{t('unassignedLabel')}</Card.Text>
                    </Card.Body>
                </Card> : null}
                {filtered.map(obj => {
                    let isSelected = false
                    if (obj[primKey] === defaultValue) {
                        isSelected = true
                    }
                    return (
                        <Card
                            key={obj[primKey] as string}
                            border={isSelected ? "success" : undefined}
                            onClick={() => handleSave(obj)}
                            className="mb-3"
                        >
                            <Card.Body>
                                {getDisplayItem(obj, isSelected)}
                            </Card.Body>
                        </Card>
                    )
                })}
            </Offcanvas.Body>
        </Offcanvas>
    </Row>

    return view
}

export default EditList