import { Button, Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Html5QrcodePlugin from "../Html5QrcodePlugin"
import { skipToken } from "@reduxjs/toolkit/query"
import { useNavigate, useParams } from "react-router-dom"
import { useFindProductsByBarcodeQuery } from "../../Store/Product/Product.service"
import { IProduct } from "../../Types"
import { useGetStoreQuery } from "../../Store/Store/Store.service"
import * as Icon from 'react-bootstrap-icons'
import { useAddListProductMutation } from "../../Store/ListProduct/ListProduct.service"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { ApiError } from "../../Services/BaseApi"

function Scanner() {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const [barcode, setBarcode] = useState('')
    const { eventId, listId } = useParams()

    const args = eventId && listId && barcode ? { eventId, listId, sku: barcode } : skipToken
    const findQuery = useFindProductsByBarcodeQuery(args)

    const [products, setProducts] = useState<(IProduct & { shouldImport?: boolean })[]>([])
    const [addProduct, addProductResult] = useAddListProductMutation()
    const [hasSelectedProducts, setHasSelectedProducts] = useState(false)

    useEffect(() => {
        setProducts([])
    }, [
        barcode,
    ])

    useEffect(() => {
        if (!findQuery.isLoading && findQuery.isSuccess && findQuery.data?.length) {
            const p: (IProduct & { shouldImport?: boolean })[] = []
            for (const product of findQuery.data) {
                p.push({
                    ...product,
                    shouldImport: false,
                })
            }
            setProducts(p)
        }
    }, [
        findQuery.isLoading,
        findQuery.isSuccess,
        findQuery.data,
    ])

    useEffect(() => {
        if (products.length) {
            const selectedProducts = products.filter(p => p.shouldImport)
            if (selectedProducts.length) {
                setHasSelectedProducts(true)
            } else {
                setHasSelectedProducts(false)
            }
        } else {
            setHasSelectedProducts(false)
        }
    }, [
        products,
    ])

    const onChange = async () => {
        if (products.length && eventId && listId) {
            for (const p of products) {
                if (p.shouldImport) {
                    try {
                        await addProduct({
                            eventId,
                            listId,
                            title: p.title,
                            description: p.description,
                            qty: 1,
                            productId: p._id,
                        }).unwrap()
                    } catch (err) {
                        dispatch(setMessage((err as ApiError).data.error))
                    }
                }
            }
            setProducts([])
            setBarcode('')
            navigate(`/app/events/${eventId}/lists/${listId}`)
        }
    }

    const markImport = (index: number, shouldImport: boolean | undefined) => {
        const p: (IProduct & { shouldImport?: boolean })[] = []
        for (let i = 0; i < products.length; i++) {
            if (i === index) {
                p.push({ ...products[i], shouldImport: shouldImport ?? undefined })
            } else {
                p.push({ ...products[i] })
            }
        }
        setProducts(p)
    }

    return (
        <Container>
            <Row className="mb-3">
                <Col>
                    <h3>{t('scanBarcodeTitle')}</h3>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Html5QrcodePlugin
                        qrCodeErrorCallback={err => { }}
                        qrCodeSuccessCallback={setBarcode}
                    />
                </Col>
            </Row>
            {barcode ? <Row className="mb-3">
                <Col>{t('scannedBarcodeText')}: {barcode}</Col>
            </Row> : null}
            {products.length ? <Row>
                <Col>
                    <ListGroup className="mb-3">
                        {products.map((product, idx) => <ListGroupItem key={idx}>
                            <ProductExistingProduct
                                product={product}
                                index={idx}
                                shouldImport={product.shouldImport}
                                markImport={markImport}
                            />
                        </ListGroupItem>)}
                    </ListGroup>
                    <div className="d-grid gap-2 mb-3">
                        <Button
                            disabled={addProductResult.isLoading || !hasSelectedProducts}
                            variant="primary"
                            onClick={() => onChange()}
                        >{t('importProductsButtonLabel')}</Button>
                    </div>
                </Col>
            </Row> : null}
            {barcode && !findQuery.isLoading && !products.length ? <Row>
                <Col>{t('productsNotFoundText')}</Col>
            </Row> : null}
        </Container>
    )
}

export default Scanner

function ProductExistingProduct({
    product,
    index,
    shouldImport,
    markImport,
}: {
    product: IProduct
    index: number
    shouldImport?: boolean
    markImport: (index: number, shouldImport?: boolean | undefined) => void
}) {

    const [importProduct, setImportProduct] = useState(shouldImport)

    const args = product.storeId ? { storeId: product.storeId } : skipToken
    const storeQuery = useGetStoreQuery(args)

    useEffect(() => {
        markImport(index, importProduct ?? undefined)
    }, [importProduct, index])

    return (
        <Row>
            {product.imageUrls?.length ? <Col xs="auto"><img src={product.imageUrls[0]} width={50} /></Col> : null}
            <Col>
                <div>{product.title}</div>
                {storeQuery.data ? <div className="small">{storeQuery.data.name}</div> : null}
            </Col>
            <Col xs="auto">
                <Button
                    variant="link"
                    onClick={() => setImportProduct(!importProduct)}
                >{importProduct ? <Icon.Check className="text-success" /> : <Icon.Plus className="text-primary" />}</Button>
            </Col>
        </Row>
    )
}