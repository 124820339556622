import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useDeleteStoreMutation, useGetStoreQuery, useUpdateStoreMutation } from "../../Store/Store/Store.service"
import { skipToken } from "@reduxjs/toolkit/query"
import { Breadcrumb, Button, Col, Container, ListGroup, Row } from "react-bootstrap"
import EditText from "../EditResource/EditText"
import * as Icon from 'react-bootstrap-icons'
import EditTextarea from "../EditResource/EditTextarea"
import Loading from "../Loading"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { ApiError } from "../../Services/BaseApi"
import { useMemo } from "react"

function StoreEdit() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { storeId } = useParams()

    const arg = storeId ? { storeId } : skipToken
    const storeQuery = useGetStoreQuery(arg)

    const dispatch = useDispatch<AppDispatch>()
    const [updateStore, updateStoreResult] = useUpdateStoreMutation()
    const [deleteStore, deleteStoreResult] = useDeleteStoreMutation()

    const handleUpdate = async (value: string, key: "name" | "description" | "address") => {
        if (!storeQuery.data) {
            return
        }
        try {
            const q: { [index: string]: string | number } = {}
            q[key] = value
            await updateStore({ storeId: storeQuery.data._id, ...q }).unwrap()
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const onDelete = async () => {
        if (!storeQuery.data) {
            return
        }
        const confirm = window.confirm(`Delete store ${storeQuery.data.name}?`)
        if (confirm) {
            if (!storeQuery.data) {
                return
            }
            try {
                await deleteStore(storeQuery.data._id).unwrap()
                navigate(`/app/stores`)
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }
    }

    const view = useMemo(() => {
        let view = <Row>
            <Col><Loading /></Col>
        </Row>
        if (!storeQuery.isLoading && storeQuery.isSuccess && storeQuery.data) {
            view = <Row>
                <Col>
                    <ListGroup>
                        <ListGroup.Item>
                            <EditText
                                icon={<Icon.Fonts />}
                                disabled={updateStoreResult.isLoading}
                                defaultValue={storeQuery.data.name}
                                placeholder={t('storeNameInputPlaceholder')}
                                handleUpdate={text => handleUpdate(text, "name")}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <EditTextarea
                                icon={<Icon.CardText />}
                                disabled={updateStoreResult.isLoading}
                                defaultValue={storeQuery.data.description}
                                placeholder={t('addDescriptionPlaceholder')}
                                handleUpdate={text => handleUpdate(text, "description")}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <EditText
                                icon={<Icon.GeoAlt />}
                                disabled={updateStoreResult.isLoading}
                                defaultValue={storeQuery.data.address}
                                placeholder={t('addAddressInputPlaceholder')}
                                handleUpdate={text => handleUpdate(text, "address")}
                            />
                        </ListGroup.Item>
                    </ListGroup>

                    <div className="d-grid gap-2 mt-3">
                        <Button
                            variant="danger"
                            disabled={deleteStoreResult.isLoading}
                            onClick={onDelete}
                        ><Icon.Trash /> {t('deleteButtonLabel')}</Button>
                    </div>

                    <div className="d-grid gap-2 mt-3">
                        <Button
                            variant="link"
                            disabled={deleteStoreResult.isLoading}
                            onClick={() => navigate('/app/stores')}
                        >{t('backButtonLabel')}</Button>
                    </div>
                </Col>
            </Row>
        }
        return view
    }, [
        storeQuery.isLoading,
        storeQuery.isSuccess,
        storeQuery.data,
    ])

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app')}>{t('homeTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate(`/app/stores`)}>{t('storesTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{t('editStoreTitle')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            {view}
        </Container>
    )
}

export default StoreEdit