import { Breadcrumb, Button, Card, Col, Container, Form, Modal, Row } from "react-bootstrap"
import * as Icon from 'react-bootstrap-icons'
import { useAddStoreMutation, useDeleteStoreMutation, useListStoresQuery } from "../../Store/Store/Store.service"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { IStore } from "../../Types"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { ApiError } from "../../Services/BaseApi"
import Loading from "../Loading"
import { useState } from "react"
import { useForm } from "react-hook-form"

function Stores() {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const storesQuery = useListStoresQuery('')

    const [showAdd, setShowAdd] = useState(false)

    const onAdd = (store: IStore) => {
        setShowAdd(false)
        navigate(`/app/stores/${store._id}/edit`)
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app')}>{t('homeTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{t('storesTitle')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col><h3>{t('myStoresTitle')}</h3></Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <div className="d-grid gap-2">
                        <Button
                            variant="primary"
                            onClick={() => setShowAdd(true)}
                        >{t('addButtonLabel')}</Button>
                    </div>
                </Col>
            </Row>
            {storesQuery.data?.length ? <Row>
                <Col>
                    {storesQuery.data?.map(store => <StoreItem key={store._id} store={store} />)}
                </Col>
            </Row> : <Row>
                <Col>
                    {t('noStores')}
                </Col>
            </Row>}
            <StoreAddModal show={showAdd} onHide={() => setShowAdd(false)} onAdd={onAdd} />
        </Container>
    )
}

export default Stores

function StoreItem({ store }: { store: IStore }) {

    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const [deleteStore, deleteStoreResult] = useDeleteStoreMutation()

    const onDelete = async () => {
        if (!store) {
            return
        }
        const confirm = window.confirm(`Delete store ${store.name}?`)
        if (confirm) {
            try {
                await deleteStore(store._id).unwrap()
                navigate(`/app/stores`)
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }
    }

    return (
        <Card className="mb-3">
            <Card.Body>
                <Row>
                    <Col className="fw-bold">{store.name}</Col>
                </Row>
                <Row>
                    <Col>{store.description}</Col>
                </Row>
                <Row>
                    <Col>
                        {store.address ?
                            <div><a href={`https://maps.google.com/?q=${store.address}`} target="_blank" rel="noreferrer">{store.address}</a></div> :
                            null}
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col></Col>
                    <Col xs="auto"><Icon.Pencil onClick={() => navigate(`/app/stores/${store._id}/edit`)} /></Col>
                    <Col xs="auto"><Icon.Trash className="text-danger" onClick={() => onDelete()} /></Col>
                </Row>
            </Card.Footer>
        </Card>
    )
}

function StoreAddModal({ show, onHide, onAdd }: { show: boolean, onHide: () => void, onAdd: (store: IStore) => void }) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [addStore, addStoreResult] = useAddStoreMutation()

    const addStoreCallback = async (name: string) => {
        try {
            const store = await addStore(name).unwrap()
            onAdd(store)
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const initState = {
        name: "",
    }

    const [initialValues] = useState(initState)

    const onSubmit = (values: { name: string }) => {
        addStoreCallback(values.name)
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        defaultValues: initialValues
    })

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t('addStoreInputPlaceholder')}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Control
                            type="text"
                            placeholder={t('storeNameInputPlaceholder')}
                            {...register("name", { required: t('nameInputRequiredError') })}
                        />
                        {errors.name && (
                            <Form.Text className="text-danger">
                                {errors.name.message}
                            </Form.Text>
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        {t('closeLabel')}
                    </Button>
                    <Button
                        type='submit'
                        variant="primary"
                        disabled={!isValid || addStoreResult.isLoading}
                    >
                        {addStoreResult.isLoading ? <Loading /> : t('addButtonLabel')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}