import { skipToken } from "@reduxjs/toolkit/query"
import { Button, Col, Container, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { ApiError } from "../../Services/BaseApi"
import { setMessage } from "../../Store/Toast/Toast.slice"
import Loading from "../Loading"
import { useValidateShoppingListInvitationQuery, useAcceptShoppingListInvitationMutation } from "../../Store/ShoppingList/ShoppingList.service"
import { useTranslation } from "react-i18next"

function ListInvitation() {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const { token } = useParams()

    const args = token ? token : skipToken
    const { data, isLoading, isSuccess } = useValidateShoppingListInvitationQuery(args)
    const [acceptInvitation, acceptInvitationResult] = useAcceptShoppingListInvitationMutation()

    useEffect(() => {
        if (!isLoading && isSuccess && data && data.status === "ACCEPTED") {
            navigate(`/app/shared-events/${data.eventId}/shared-lists/${data.listId}`)
        }
    }, [data, isLoading, isSuccess, navigate])

    const view = useMemo(() => {
        let view = <Container>
            <Row className="text-center">
                <Col><Loading /></Col>
            </Row>
        </Container>
        if (!isLoading && isSuccess && data && data.status === "PENDING") {
            view = <Container>
                <Row className="text-center">
                    <Col className="fs-3">{data.title}</Col>
                </Row>
                <Row className="text-center">
                    <Col>{t('listInvitationText', { inviterName: data.inviterName })}</Col>
                </Row>
                <Row className="text-center mt-3">
                    <Col>
                        <Button
                            disabled={acceptInvitationResult.isLoading}
                            variant="primary"
                            onClick={async () => {
                                if (!token || !data) {
                                    return
                                }
                                try {
                                    await acceptInvitation(token).unwrap()
                                    navigate(`/app/shared-events/${data.eventId}/shared-lists/${data.listId}`)
                                } catch (err) {
                                    dispatch(setMessage((err as ApiError).data.error))
                                    navigate(`/app/shared-events`)
                                }
                            }}
                        >
                            {acceptInvitationResult.isLoading ? <Loading /> : t('acceptInvitationButtonLabel')}
                        </Button>
                    </Col>
                </Row>
            </Container>
        }
        if (!isLoading && !isSuccess) {
            view = <Container>
                <Row className="text-center">
                    <Col>{t('invalidInvitationText')}</Col>
                </Row>
            </Container>
        }
        return view
    }, [
        data,
        isLoading,
        isSuccess,
        acceptInvitationResult.isLoading,
        t,
        acceptInvitation,
        dispatch,
        navigate,
        token,
    ])

    return (
        view
    )
}

export default ListInvitation