import React, { useEffect } from "react"
import { Button, Form, InputGroup } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { AppDispatch } from "../../Store/Store"
import { useDispatch } from "react-redux"
import { showProductFinder } from "../../Store/Product/Product.slice"
import { currencyCodesType, IProduct } from "../../Types"
import ProductFinder from "../ProductFinder"
import { useTranslation } from "react-i18next"

export type AddListProductFormData = {
    title: string
    productId?: string
    description?: string
    storeId?: string
    storeName?: string
    qty?: number
    unitPrice?: number
    weightGr?: number
    currency?: string
}

interface IAddListProductFormProps {
    handleSubmit(data: AddListProductFormData): void
    disabled: boolean
    currencyCode: currencyCodesType
}

const AddListProductForm: React.FC<IAddListProductFormProps> = (props: IAddListProductFormProps) => {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(showProductFinder(true))
    }, [dispatch])

    const initState = {
        title: "",
        productId: "",
        description: "",
        storeId: "",
        storeName: "",
        qty: 1,
        unitPrice: 0,
        weightGr: 0,
        currency: props.currencyCode,
    }

    const [initialValues] = React.useState(initState)

    const onSubmit = (values: AddListProductFormData) => {
        props.handleSubmit(values)
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        getValues,
        trigger,
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        defaultValues: initialValues
    })

    const onProductSelect = async (product: IProduct) => {
        dispatch(showProductFinder(false))
        setValue('title', product.title)
        setValue('productId', product._id)
        trigger()
    }

    const decQty = () => {
        const values = getValues()
        let qty = values.qty
        if (qty - 1 > 0) {
            qty--
        }
        setValue('qty', qty)
    }

    const incQty = () => {
        const values = getValues()
        let qty = values.qty + 1
        setValue('qty', qty)
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="title">
                <Form.Label>{t('nameTitle')}</Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t('listProductTitleValuePlaceholder')}
                    onClick={() => dispatch(showProductFinder(true))}
                    {...register("title", { required: t('nameInputRequiredError') })}
                />
                {errors.title && (
                    <Form.Text className="text-danger">
                        {errors.title.message}
                    </Form.Text>
                )}
            </Form.Group>
            <ProductFinder onProductSelect={onProductSelect} />
            <Form.Group className="mb-3" controlId="qty">
                <Form.Label>{t('qtyLabel')}</Form.Label>
                <InputGroup className="mb-3">
                    <InputGroup.Text
                        onClick={decQty}
                    >-</InputGroup.Text>
                    <Form.Control
                        type="number"
                        style={{ textAlign: 'center' }}
                        {...register("qty")}
                    />
                    <InputGroup.Text
                        onClick={incQty}
                    >+</InputGroup.Text>
                </InputGroup>
            </Form.Group>

            <div className="d-grid gap-2">
                <Button
                    type='submit'
                    variant="primary"
                    disabled={!isValid || props.disabled}
                >{t('addProductButtonLabel')}</Button>
            </div>
        </Form>

    )
}

export default AddListProductForm