import { baseSplitApi } from "../../Services/BaseApi"
import { IShoppingList, IShoppingListInvitation } from "../../Types"
import { AddShoppingListInvitationPayload, AddShoppingListPayload, UpdateShoppingListPayload } from "./Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listShoppingLists: build.query<IShoppingList[], string>({
            query: (eventId) => '/events/' + eventId + '/lists',
            providesTags: ["ShoppingLists"]
        }),
        listRecentShoppingLists: build.query<IShoppingList[], void>({
            query: () => '/recent-shopping-lists',
            providesTags: ["ShoppingLists"]
        }),
        getShoppingList: build.query<IShoppingList, { eventId: string, listId: string }>({
            query: ({ eventId, listId }) => '/events/' + eventId + '/lists/' + listId,
            providesTags: ["ShoppingLists"]
        }),
        addShoppingList: build.mutation<IShoppingList, AddShoppingListPayload>({
            query: (payload) => ({
                url: '/events/' + payload.eventId + '/lists',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["ShoppingLists"]
        }),
        archiveShoppingList: build.mutation<void, { eventId: string, listId: string }>({
            query: ({ eventId, listId }) => ({
                url: '/events/' + eventId + '/lists/' + listId + '/archive',
                method: 'POST',
            }),
            invalidatesTags: ["ShoppingLists"]
        }),
        cloneShoppingList: build.mutation<IShoppingList, { eventId: string, listId: string, title: string }>({
            query: ({ eventId, listId, title }) => ({
                url: '/events/' + eventId + '/lists/' + listId + '/clone',
                method: 'POST',
                body: { title }
            }),
            invalidatesTags: ["ShoppingLists"]
        }),
        moveShoppingList: build.mutation<void, { eventId: string, listId: string, targetEventId: string }>({
            query: ({ eventId, listId, targetEventId }) => ({
                url: '/events/' + eventId + '/lists/' + listId + '/move',
                method: 'POST',
                body: { targetEventId },
            }),
            invalidatesTags: ["ShoppingLists"]
        }),
        updateShoppingList: build.mutation<void, UpdateShoppingListPayload>({
            query: (payload) => ({
                url: '/events/' + payload.eventId + '/lists/' + payload.listId,
                method: 'PUT',
                body: {
                    title: payload.title,
                    description: payload.description,
                    assigneeUserId: payload.assigneeUserId,
                    scheduleAt: payload.scheduleAt,
                    isComplete: payload.isComplete,
                    imageUrl: payload.imageUrl,
                    receipt: payload.receipt,
                },
            }),
            invalidatesTags: ["ShoppingLists"]
        }),
        deleteShoppingList: build.mutation<void, { eventId: string, listId: string }>({
            query: ({ eventId, listId }) => ({
                url: '/events/' + eventId + '/lists/' + listId,
                method: 'DELETE',
            }),
            invalidatesTags: ["ShoppingLists"]
        }),
        listShoppingListInvitations: build.query<IShoppingListInvitation[], string>({
            query: (eventId) => '/event-invitations/events/' + eventId,
            providesTags: ["ShoppingListInvitations"]
        }),
        addShoppingListInvitation: build.mutation<IShoppingListInvitation, AddShoppingListInvitationPayload>({
            query: (payload) => ({
                url: '/shopping-list-invitations',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["ShoppingLists", "InvitationUsers"]
        }),
        deleteShoppingListInvitation: build.mutation<IShoppingListInvitation, { listId: string, email: string }>({
            query: (body) => ({
                url: '/shopping-list-invitations',
                method: 'DELETE',
                body,
            }),
            invalidatesTags: ["ShoppingLists", "InvitationUsers"]
        }),
        validateShoppingListInvitation: build.query<{ eventId: string, listId: string, title: string, status: "PENDING" | "ACCEPTED", inviterName: string }, string>({
            query: (token) => '/shopping-list-invitations-validation/' + token,
        }),
        acceptShoppingListInvitation: build.mutation<void, string>({
            query: (token) => '/shopping-list-invitations-accept/' + token,
            invalidatesTags: ["ShoppingListInvitations", "InvitationUsers"]
        }),
        listSharedShoppingLists: build.query<IShoppingList[], string>({
            query: (eventId) => '/shared-events/' + eventId + '/shared-lists',
            providesTags: ["SharedShoppingLists"]
        }),
        getSharedShoppingList: build.query<IShoppingList, { eventId: string, listId: string }>({
            query: ({ eventId, listId }) => '/shared-events/' + eventId + '/shared-lists/' + listId,
            providesTags: ["SharedShoppingLists"]
        }),
        deleteListReceipt: build.mutation<void, { eventId: string, listId: string, receiptIndex: number }>({
            query: ({ eventId, listId, receiptIndex }) => ({
                url: '/events/' + eventId + '/lists/' + listId + '/receipt',
                method: 'DELETE',
                body: { receiptIndex },
            }),
            invalidatesTags: ["ShoppingLists"]
        }),
    }),
    overrideExisting: false,
})

export const {
    useListShoppingListsQuery,
    useListRecentShoppingListsQuery,
    useGetShoppingListQuery,
    useAddShoppingListMutation,
    useArchiveShoppingListMutation,
    useCloneShoppingListMutation,
    useMoveShoppingListMutation,
    useUpdateShoppingListMutation,
    useDeleteShoppingListMutation,
    useListShoppingListInvitationsQuery,
    useAddShoppingListInvitationMutation,
    useDeleteShoppingListInvitationMutation,
    useValidateShoppingListInvitationQuery,
    useAcceptShoppingListInvitationMutation,
    useListSharedShoppingListsQuery,
    useGetSharedShoppingListQuery,
    useDeleteListReceiptMutation,
} = api