import { useMemo } from "react"
import { Col, Card, Row } from "react-bootstrap"
import { NumericFormat } from "react-number-format"
import { CurrencyService } from "../../Services/Currency"
import { IReceipt, langCodes, langCodesType } from "../../Types"
import Loading from "../Loading"
import * as Icon from 'react-bootstrap-icons'

function Receipt({
    receiptIndex,
    receipt,
    isLoading,
    onDelete,
    className,
}: {
    receiptIndex: number
    receipt: IReceipt
    isLoading: boolean
    onDelete?: (receiptIndex: number) => void
    className?: string
}) {

    const symbol = useMemo(() => {
        let symbol = '$'
        if (receipt.currencyCode) {
            const currency = CurrencyService.getCurrency(receipt.currencyCode)
            if (currency) {
                symbol = currency.symbol
            }
        }
        return symbol
    }, [
        receipt.currencyCode
    ])

    const lang = useMemo(() => {
        let langName = 'English'
        if (receipt.langCode && langCodes.includes(receipt.langCode as langCodesType)) {
            const lang = CurrencyService.getLangNameByCode(receipt.langCode as langCodesType)
            if (lang) {
                langName = lang
            }
        }
        return langName
    }, [
        receipt.langCode
    ])

    return (
        <Card className={className}>
            <Card.Img variant="top" src={receipt.imageUrl} />
            <Card.Body>
                <Row>
                    <Col>
                        <Card.Text>
                            <NumericFormat
                                value={receipt.amount}
                                displayType="text"
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={symbol}
                            />
                        </Card.Text>
                    </Col>
                    <Col className="text-end"><Card.Text>{lang}</Card.Text></Col>
                </Row>
            </Card.Body>
            {!!onDelete ? <Card.Footer className="text-center">
                {!isLoading ?
                    <Icon.Trash className="text-danger" onClick={() => onDelete(receiptIndex)} />
                    : <Loading />}
            </Card.Footer> : null}
        </Card>
    )
}

export default Receipt