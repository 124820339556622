import { skipToken } from "@reduxjs/toolkit/query"
import { useGetUserQuery } from "../../Store/UserConfig/UserConfig.service"
import { useMemo } from "react"

function UserName({
    userId,
}: {
    userId: string
}) {
    const arg = userId ?? skipToken
    const userQuery = useGetUserQuery(arg)

    const view = useMemo(() => {
        let view = null
        if (!userQuery.isLoading && userQuery.isSuccess && userQuery.data) {
            view = userQuery.data.name
        }
        return view
    }, [
        userQuery.isLoading,
        userQuery.isSuccess,
        userQuery.data,
    ])

    return (
        <>{view}</>
    )
}

export default UserName