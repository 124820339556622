import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { Route, Routes, useLocation, useNavigate, } from 'react-router-dom';
import logo from '../../Images/logo.png'
import Events from '../Events';
import Main from '../Main';
import SList from '../SList';
import Event from '../Events/event';
import { useAuth0 } from '@auth0/auth0-react';
import Account from '../Account';
import { useEffect, useState } from 'react';
import AppNotification from '../AppNotification';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store/Store';
import { setMessage } from '../../Store/Toast/Toast.slice';
import AddEvent from '../Events/add';
import AddShoppingList from '../SList/add';
import AddListProduct from '../ListProduct/add';
import { useGetUserConfigQuery, useSetUserConfigMutation } from '../../Store/UserConfig/UserConfig.service';
import { countryCodes, countryCodesType, currencyCodes, currencyCodesType, langCodes, langCodesType } from '../../Types';
import { CurrencyService } from '../../Services/Currency';
import { Badge, Col, Offcanvas, Row } from 'react-bootstrap';
import { setCountryCode, setCurrencyCode, setLangCode } from '../../Store/UserConfig/UserConfig.slice';
import EditShoppingList from '../SList/edit';
import EditEvent from '../Events/edit';
import SharedEvents from '../SharedEvents';
import SharedEvent from '../SharedEvents/event';
import AddSharedListProduct from '../ListProduct/shared-add';
import { ApiError } from '../../Services/BaseApi';
import WebPush from '../WebPush';
import ListInvitation from '../ListInvitation';
import EventInvitation from '../EventInvitation';
import Products from '../Products';
import Stores from '../Stores';
import Notifications from '../Notifications';
import * as Icon from 'react-bootstrap-icons'
import { useCountUnreadNotificationsQuery } from '../../Store/Notifications/Notifications.service';
import { showNotifications } from '../../Store/Notifications/Notifications.slice';
import i18next from '../../Translation/config';
import { useTranslation } from 'react-i18next';
import StoreEdit from '../StoreEdit';
import ProductEdit from '../ProductEdit';
import Scanner from '../SList/scanner';

function Navigation() {

    const { user, logout } = useAuth0();
    const { t } = useTranslation()

    const dispatch = useDispatch<AppDispatch>()
    const location = useLocation()
    const navigate = useNavigate()

    const countryCode = useSelector((state: RootState) => state.userConfig.countryCode)
    const currencyCode = useSelector((state: RootState) => state.userConfig.currencyCode)
    const langCode = useSelector((state: RootState) => state.userConfig.langCode)
    const showNotificationsFlag = useSelector((state: RootState) => state.notifications.show)

    const [expanded, setExpanded] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState('$')
    const [countryName, setCountryName] = useState('United States')
    const [langName, setLangName] = useState('English')
    const [dir, setDir] = useState('ltr')

    const getUserConfigQuery = useGetUserConfigQuery()
    const [setUserConfig] = useSetUserConfigMutation()
    const notificationsCountQuery = useCountUnreadNotificationsQuery()

    useEffect(() => {
        if (!getUserConfigQuery.isLoading && getUserConfigQuery.isSuccess && getUserConfigQuery.data) {
            dispatch(setCurrencyCode(getUserConfigQuery.data.currencyCode))
            dispatch(setCountryCode(getUserConfigQuery.data.countryCode))
            dispatch(setLangCode(getUserConfigQuery.data.langCode))
            i18next.changeLanguage(getUserConfigQuery.data.langCode)
            if (getUserConfigQuery.data.langCode === 'he') {
                setDir('rtl')
            } else {
                setDir('ltr')
            }
        }
    }, [getUserConfigQuery.data, getUserConfigQuery.isLoading, getUserConfigQuery.isSuccess, dispatch])

    useEffect(() => {
        const currency = CurrencyService.getCurrency(currencyCode)
        let symbol = '$'
        if (currency) {
            symbol = currency.symbol
        }
        setCurrencySymbol(symbol)
    }, [currencyCode])

    useEffect(() => {
        const countryName = CurrencyService.getCountryNameByCode(countryCode)
        let name = 'United States'
        if (countryName) {
            name = countryName
        }
        setCountryName(name)
    }, [countryCode])

    useEffect(() => {
        const langName = CurrencyService.getLangNameByCode(langCode)
        let name = 'English'
        if (langName) {
            name = langName
        }
        setLangName(name)
    }, [langCode])

    const signOut = () => {
        //dispatch(logoutAsync())
        logout({ logoutParams: { returnTo: window.location.origin } })
    }

    const onNav = (path: string) => {
        setExpanded(false)
        navigate(path)
    }

    const changeCurrency = async (code: currencyCodesType) => {
        try {
            await setUserConfig({
                currencyCode: code,
            }).unwrap()
            dispatch(setMessage(t('configurationSaved')))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const changeCountry = async (code: countryCodesType) => {
        try {
            await setUserConfig({
                countryCode: code,
            }).unwrap()
            dispatch(setMessage(t('configurationSaved')))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const changeLang = async (code: langCodesType) => {
        try {
            await setUserConfig({
                langCode: code,
            }).unwrap()
            dispatch(setMessage(t('configurationSaved')))
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    return (
        <div dir={dir}>
            <Navbar expand={'sm'} expanded={expanded} className="bg-body-tertiary mb-3">
                <Container fluid>
                    <Navbar.Brand onClick={() => onNav('/app')}>
                        <img
                            alt="Shopping List"
                            src={logo}
                            height="30"
                            className="d-inline-block align-top"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls={`offcanvasNavbar-expand-sm`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-sm`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton onHide={() => setExpanded(false)}>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
                                {t('menuTitle')}
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>

                            <Nav activeKey={location.pathname} className="me-auto">
                                <Nav.Item>
                                    <Nav.Link onClick={() => onNav('/app')}>{t('homeTitle')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => onNav('/app/events')}>{t('eventsTitle')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => onNav('/app/products')}>{t('productsTitle')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => onNav('/app/stores')}>{t('storesTitle')}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Nav>
                                <NavDropdown title={langName} id="basic-nav-dropdown">
                                    {langCodes.map(code => {
                                        const langName = CurrencyService.getLangNameByCode(code)
                                        return (
                                            <NavDropdown.Item
                                                key={code}
                                                active={code === langCode}
                                                onClick={() => changeLang(code)}
                                            >
                                                {langName}
                                            </NavDropdown.Item>
                                        )
                                    })}
                                </NavDropdown>
                                <NavDropdown title={currencySymbol} id="basic-nav-dropdown">
                                    {currencyCodes.map(code => {
                                        const c = CurrencyService.getCurrency(code)
                                        if (!c) {
                                            return null
                                        }
                                        return (
                                            <NavDropdown.Item
                                                key={code}
                                                active={code === currencyCode}
                                                onClick={() => changeCurrency(code)}
                                            >
                                                <Row>
                                                    <Col>{c?.name}</Col>
                                                    <Col className='text-end'>{c?.symbol}</Col>
                                                </Row>
                                            </NavDropdown.Item>
                                        )
                                    })}
                                </NavDropdown>
                                <NavDropdown title={countryName} id="basic-nav-dropdown">
                                    {countryCodes.map(code => {
                                        const countryName = CurrencyService.getCountryNameByCode(code)
                                        return (
                                            <NavDropdown.Item
                                                key={code}
                                                active={code === countryCode}
                                                onClick={() => changeCountry(code)}
                                            >
                                                <span>{countryName?.substring(0, 30)}{undefined !== countryName?.length && countryName.length > 30 ? '...' : ''}</span>
                                            </NavDropdown.Item>
                                        )
                                    })}
                                </NavDropdown>
                            </Nav>
                            <Nav activeKey={location.pathname}>
                                <Nav.Item>
                                    <Nav.Link onClick={() => {
                                        setExpanded(false)
                                        dispatch(showNotifications(!showNotificationsFlag))
                                    }}>
                                        {undefined !== notificationsCountQuery.data && notificationsCountQuery.data.count > 0 ? <Badge>{notificationsCountQuery.data.count}</Badge> : null}
                                        {undefined !== notificationsCountQuery.data && notificationsCountQuery.data.count > 0 ? <Icon.BellFill /> : <Icon.Bell />}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Nav>
                                <NavDropdown title={user?.name} id="basic-nav-dropdown">
                                    <NavDropdown.Item onClick={() => onNav('/app/account')}>
                                        {t('accountTitle')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item>
                                        {t('termsOfUseTitle')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        {t('privacyPolicyTitle')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={() => signOut()}>
                                        {t('signOutTitle')}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
            <Routes>
                <Route path='/' element={<Main />} />
                <Route path='/account' element={<Account />} />
                <Route path='/events' element={<Events />} />
                <Route path='/events-add' element={<AddEvent />} />
                <Route path='/events/:eventId' element={<Event />} />
                <Route path='/events-edit/:eventId' element={<EditEvent />} />
                <Route path='/events/:eventId/lists-add' element={<AddShoppingList />} />
                <Route path='/events/:eventId/lists/:listId' element={<SList />} />
                <Route path='/events/:eventId/lists-edit/:listId' element={<EditShoppingList />} />
                <Route path='/events/:eventId/lists/:listId/products-add' element={<AddListProduct />} />
                <Route path='/events/:eventId/lists/:listId/products-barcode-scan' element={<Scanner />} />
                <Route path='/shared-events' element={<SharedEvents />} />
                <Route path='/shared-events/:eventId' element={<SharedEvent />} />
                <Route path='/shared-events/:eventId/shared-lists/:listId' element={<SList isShared={true} />} />
                <Route path='/shared-events/:eventId/shared-lists/:listId/shared-products-add' element={<AddSharedListProduct />} />
                <Route path='/event-invitations/:token' element={<EventInvitation />} />
                <Route path='/list-invitations/:token' element={<ListInvitation />} />
                <Route path='/products' element={<Products />} />
                <Route path='/products/:productId/edit' element={<ProductEdit />} />
                <Route path='/stores' element={<Stores />} />
                <Route path='/stores/:storeId/edit' element={<StoreEdit />} />
            </Routes>
            <WebPush />
            <Notifications />
            <AppNotification />
        </div>
    );
}

export default Navigation;
