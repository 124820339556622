import { Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"

function Loading() {

    const { t } = useTranslation()

    return (
        <Spinner animation="grow" role="status" size="sm">
            <span className="visually-hidden">{t('loadingText')}...</span>
        </Spinner>
    )
}

export default Loading