import { Link, useNavigate } from "react-router-dom"
import { Badge, Button, Card, Col, Form, Modal, Offcanvas, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import * as Icon from 'react-bootstrap-icons'
import { currencyCodesType, IEvent, IReceipt, IShoppingList, Member } from "../../Types"
import { useMemo, useState } from "react"
import EventUserAvatars from "../EventUserAvatars"
import UserAvatar from "../UserAvatar"
import { skipToken } from "@reduxjs/toolkit/query"
import { useGetUserQuery } from "../../Store/UserConfig/UserConfig.service"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { ApiError } from "../../Services/BaseApi"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { useAddShoppingListMutation, useArchiveShoppingListMutation, useCloneShoppingListMutation, useDeleteListReceiptMutation, useDeleteShoppingListMutation, useUpdateShoppingListMutation } from "../../Store/ShoppingList/ShoppingList.service"
import { useGetEventQuery } from "../../Store/Event/Event.service"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { CurrencyService } from "../../Services/Currency"
import { NumericFormat } from "react-number-format"
import ReceiptsUpload from "../EditResource/EditReceipts/ReceiptsUpload"
import { useForm } from "react-hook-form"
import Loading from "../Loading"
import { AddShoppingListFormData } from "../SList/add-form"

function ShoppingListItem({
    event,
    list,
    canEdit,
    isShared,
}: {
    event: IEvent
    list: IShoppingList
    canEdit: boolean
    isShared: boolean
}) {

    const eventArgs = list.eventId ? { eventId: list.eventId } : skipToken
    const eventQuery = useGetEventQuery(eventArgs)

    const members = useMemo(() => {
        let members1: Member[] = []
        let members2: Member[] = []
        if (event.members?.length) {
            members1 = event.members.filter(member => !!member.userId).map(member => {
                return {
                    userId: member.userId,
                    email: member.email,
                    name: member.name,
                    picture: member.picture,
                    isMe: false,
                }
            }) as Member[]
        }
        if (list.members?.length) {
            members2 = list.members.filter(member => !!member.userId).map(member => {
                return {
                    userId: member.userId,
                    email: member.email,
                    name: member.name,
                    picture: member.picture,
                    isMe: false,
                }
            }) as Member[]
        }

        return [...members1, ...members2]
    }, [list.members, event.members])

    return (
        <Card
            className="mb-3"
            border={list.isComplete ? "secondary" : undefined}
        >
            <ItemImage
                list={list}
                isShared={isShared}
            />
            <Card.Body>
                <ItemTitle
                    list={list}
                    isShared={isShared}
                />
                <ItemEvent
                    event={eventQuery.data}
                />
                <ItemAssignee
                    assigneeUserId={list.assigneeUserId}
                />
                <ItemMembers
                    members={members}
                />
                <ItemReceipts
                    eventId={list.eventId}
                    listId={list._id}
                    receipts={list.receipts}
                />
                <ItemDescription
                    description={list.description}
                    isComplete={list.isComplete}
                />
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col>
                        <ItemFooterComplete isComplete={list.isComplete} />
                    </Col>
                    {eventQuery.data ? <ItemFooterActions
                        list={list}
                        event={eventQuery.data}
                        canEdit={canEdit}
                    /> : null}
                </Row>
            </Card.Footer>
        </Card>
    )
}

export default ShoppingListItem

function ItemImage({ list, isShared }: { list?: IShoppingList, isShared?: boolean }) {

    const navigate = useNavigate()

    const view = useMemo(() => {
        let view = null
        if (list?.imageUrl) {
            view = <Card.Img
                onClick={() => navigate(`/app/${isShared ? 'shared-events' : 'events'}/${list.eventId}/${isShared ? 'shared-lists' : 'lists'}/${list._id}`)}
                src={list.imageUrl}
            />
        }
        return view
    }, [
        list?.imageUrl,
    ])

    return (
        view
    )
}

function ItemTitle({ list, isShared }: { list: IShoppingList, isShared: boolean }) {

    const navigate = useNavigate()

    const badgeBg = useMemo(() => {
        let bg = "warning"

        if (list.isComplete) {
            bg = "secondary"
        }
        if (moment(list.scheduleAt).isBefore()) {
            bg = "secondary"
        }

        return bg
    }, [
        list.isComplete,
        list.scheduleAt,
    ])

    return (
        <Card.Title
            onClick={() => navigate(`/app/${isShared ? 'shared-events' : 'events'}/${list.eventId}/${isShared ? 'shared-lists' : 'lists'}/${list._id}`)}
        >
            <Row>
                <Col className={list.isComplete ? 'text-muted' : undefined}>{list.title}</Col>
                {list.scheduleAt ? <Col xs="auto" className="text-end">
                    {badgeBg === "warning" ? <Badge
                        bg={badgeBg}
                        pill
                    >
                        DateService.formatDate(list.scheduleAt, "lll")
                        <Icon.Clock className="ms-1" />
                    </Badge> : null}
                    {badgeBg === "secondary" ? <Icon.Clock className="ms-1 text-muted" /> : null}
                </Col> : null}
            </Row>
        </Card.Title>
    )
}

function ItemEvent({ event }: { event?: IEvent }) {

    const view = useMemo(() => {
        let view = null
        if (event) {
            view = <Card.Text>
                <Link to={`/app/events/${event._id}`}>{event.title}</Link>
            </Card.Text>
        }
        return null
    }, [
        event,
    ])

    return (
        view
    )
}

function ItemMembers({ members }: { members: Member[] }) {

    const { t } = useTranslation()

    let view = null
    if (members.length) {
        view = <Row className="mb-1">
            <Col xs="auto">
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={props => (
                        <Tooltip id="button-tooltip" {...props}>
                            {t('membersTitle')}
                        </Tooltip>
                    )}
                >
                    <Icon.People />
                </OverlayTrigger>
            </Col>
            <Col>
                <EventUserAvatars users={members} />
            </Col>
        </Row>
    }

    return (
        view
    )
}

function ItemAssignee({ assigneeUserId }: { assigneeUserId?: string }) {

    const { t } = useTranslation()

    const args = assigneeUserId ?? skipToken
    const userQuery = useGetUserQuery(args)

    const view = useMemo(() => {
        let view = <Row className="mb-1">
            <Col xs="auto">
                <Icon.Person /> {t('unassignedLabel')}
            </Col>
        </Row>
        if (!userQuery.isLoading && userQuery.isSuccess && userQuery.data) {
            view = <Row className="mb-1">
                <Col xs="auto">
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={props => (
                            <Tooltip id="button-tooltip" {...props}>
                                {t('assigneeTitle')}
                            </Tooltip>
                        )}
                    >
                        <Icon.Person />
                    </OverlayTrigger>
                </Col>
                <Col>
                    <UserAvatar
                        user={userQuery.data}
                    /> {userQuery.data.name}
                </Col>
            </Row>
        }
        return view
    }, [
        userQuery.isLoading,
        userQuery.isSuccess,
        userQuery.data,
        t,
    ])

    return (
        view
    )
}

function ItemReceipts({
    eventId,
    listId,
    receipts,
}: {
    eventId?: string
    listId?: string
    receipts?: IReceipt[]
}) {

    const { t } = useTranslation()

    const [show, setShow] = useState(false)
    const dispatch = useDispatch<AppDispatch>()

    const onHide = () => setShow(false)
    const [updateShoppingList, updateShoppingListResult] = useUpdateShoppingListMutation()
    const [deleteListReceipt, deleteListReceiptResult] = useDeleteListReceiptMutation()

    const handleUpdate = async (value: IReceipt, key: "receipt") => {
        if (!eventId || !listId) {
            return
        }
        try {
            const q: { [index: string]: IReceipt } = {}
            q[key] = value
            await updateShoppingList({ eventId, listId, ...q }).unwrap()
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const onDeleteReceipt = async (receiptIndex: number) => {
        if (!eventId || !listId) {
            return
        }
        const confirm = window.confirm(`Delete receipt?`)
        if (confirm) {
            try {
                await deleteListReceipt({
                    eventId,
                    listId,
                    receiptIndex,
                }).unwrap()
                dispatch(setMessage(t('receiptRemovedNotification')))
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }
    }

    const totals: { code: currencyCodesType, amount?: number }[] = useMemo(() => {
        const totalsIndex: { [key in currencyCodesType]?: number } = {}
        const totals: { code: currencyCodesType, amount?: number }[] = []
        if (receipts?.length) {
            for (const receipt of receipts) {
                const code = receipt.currencyCode
                if (undefined === totalsIndex[code]) {
                    totalsIndex[code] = 0
                }
                if (undefined !== totalsIndex[code]) {
                    totalsIndex[code] = (totalsIndex[code] ?? 0) + receipt.amount
                }
            }
            const keys = Object.keys(totalsIndex)
            if (keys.length) {
                for (const key of keys) {
                    const indexKey = key as currencyCodesType
                    totals.push({
                        code: indexKey,
                        amount: totalsIndex[indexKey],
                    })
                }
            }
        }
        return totals
    }, [
        receipts,
        eventId,
        listId,
    ])

    let view = <Row className="mb-1">
        <Col xs="auto">
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={props => (
                    <Tooltip id="button-tooltip" {...props}>
                        {t('receiptsLabel')}
                    </Tooltip>
                )}
            >
                <Icon.Cash />
            </OverlayTrigger>
        </Col>
        <Col>
            <NumericFormat
                value={0}
                displayType="text"
                thousandSeparator={true}
                fixedDecimalScale={true}
                decimalScale={2}
            />
        </Col>
        <Col xs="auto">
            <Button
                variant="link"
                size="sm"
                onClick={() => setShow(true)}
            ><Icon.Eye /> {t('viewButtonLabel')}</Button>
            <Offcanvas show={show} onHide={onHide}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t('uploadReceiptsTitle')}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ReceiptsUpload
                        disabled={updateShoppingListResult.isLoading}
                        onChange={receipt => handleUpdate(receipt, "receipt")}
                        receipts={receipts}
                        isLoading={deleteListReceiptResult.isLoading}
                        onDelete={onDeleteReceipt}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </Col>
    </Row>
    if (totals.length) {
        view = <Row className="mb-1">
            <Col xs="auto">
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={props => (
                        <Tooltip id="button-tooltip" {...props}>
                            {t('receiptsLabel')}
                        </Tooltip>
                    )}
                >
                    <Icon.Cash />
                </OverlayTrigger>
            </Col>
            <Col>
                {totals.map((total, idx) => {
                    let symbol = '$'
                    if (total.code) {
                        const currency = CurrencyService.getCurrency(total.code)
                        if (currency) {
                            symbol = currency.symbol
                        }
                    }
                    return (
                        <span key={idx}>
                            {idx > 0 ? ', ' : ''}
                            <NumericFormat
                                value={total.amount}
                                displayType="text"
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={symbol}
                            />
                        </span>
                    )
                })}
            </Col>
            <Col xs="auto">
                <Button
                    variant="link"
                    size="sm"
                    onClick={() => setShow(true)}
                ><Icon.Eye /> {t('viewButtonLabel')}</Button>
                <Offcanvas show={show} onHide={onHide}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{t('uploadReceiptsTitle')}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <ReceiptsUpload
                            disabled={updateShoppingListResult.isLoading}
                            onChange={receipt => handleUpdate(receipt, "receipt")}
                            receipts={receipts}
                            isLoading={deleteListReceiptResult.isLoading}
                            onDelete={onDeleteReceipt}
                        />
                    </Offcanvas.Body>
                </Offcanvas>
            </Col>
        </Row>
    }

    return (
        view
    )
}

function ItemDescription({ description, isComplete }: { description?: string, isComplete?: boolean }) {

    const view = useMemo(() => {
        let view = null
        if (description) {
            view = <Row>
                <Col className={isComplete ? 'text-muted small' : 'small'}>{description}</Col>
            </Row>
        }
        return view
    }, [
        description,
        isComplete,
    ])

    return (
        view
    )
}

function ItemFooterComplete({ isComplete }: { isComplete?: boolean }) {

    const { t } = useTranslation()

    const view = useMemo(() => {
        let view = null
        if (isComplete) {
            view = <Badge bg="success" pill className="ms-1">{t('completeLabel')}</Badge>
        }
        return view
    }, [
        isComplete,
    ])

    return (
        view
    )
}

function ItemFooterActions({ list, event, canEdit }: { list: IShoppingList, event: IEvent, canEdit: boolean }) {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()

    const [show, setShow] = useState(false)

    const [updateShoppingList, updateShoppingListResult] = useUpdateShoppingListMutation()
    const [deleteShoppingList, deleteShoppingListResult] = useDeleteShoppingListMutation()
    const [archiveShoppingList, archiveShoppingListResult] = useArchiveShoppingListMutation()

    const onHide = () => setShow(false)

    const onComplete = async (list: IShoppingList) => {
        if (updateShoppingListResult.isLoading) {
            return
        }
        try {
            const confirm = window.confirm(`Mark shopping list "${list.title}" as complete?`)
            if (confirm) {
                await updateShoppingList({ eventId: list.eventId, listId: list._id, isComplete: true }).unwrap()
                dispatch(setMessage(t('shoppingListUpdatedNotification')))
            }
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const onIncomplete = async (list: IShoppingList) => {
        if (updateShoppingListResult.isLoading) {
            return
        }
        try {
            const confirm = window.confirm(`Mark shopping list "${list.title}" as incomplete?`)
            if (confirm) {
                await updateShoppingList({ eventId: list.eventId, listId: list._id, isComplete: false }).unwrap()
                dispatch(setMessage(t('shoppingListUpdatedNotification')))
            }
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const onDelete = async (list: IShoppingList) => {
        if (deleteShoppingListResult.isLoading) {
            return
        }
        try {
            const confirm = window.confirm(`Delete shopping list "${list.title}"?`)
            if (confirm) {
                await deleteShoppingList({ eventId: list.eventId, listId: list._id }).unwrap()
                dispatch(setMessage(t('shoppingListRemovedNotification')))
            }
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const onArchive = async (list: IShoppingList) => {
        if (archiveShoppingListResult.isLoading) {
            return
        }
        try {
            const confirm = window.confirm(`Archive shopping list "${list.title}"?`)
            if (confirm) {
                await archiveShoppingList({ eventId: list.eventId, listId: list._id }).unwrap()
                dispatch(setMessage(t('shoppingListArchiveNotification')))
            }
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const view = useMemo(() => {
        let view = null
        if (canEdit) {
            view = <>
                {!list.isComplete ? <Col xs="auto" className="text-end">
                    <Icon.Check
                        className="text-success"
                        onClick={() => onComplete(list)}
                    />
                </Col> : null}
                {list.isComplete ? <Col xs="auto" className="text-end">
                    <Icon.X
                        onClick={() => onIncomplete(list)}
                    />
                </Col> : null}
                <Col xs="auto" className="text-end">
                    <Icon.Pencil
                        onClick={() => {
                            navigate(`/app/events/${list.eventId}/lists-edit/${list._id}?r=/app/events/${list.eventId}`)
                        }}
                    />
                </Col>
                <Col xs="auto" className="text-end">
                    <Icon.Copy
                        onClick={() => setShow(true)}
                    />
                    <CopyModal
                        list={list}
                        show={show}
                        onHide={onHide}
                    />
                </Col>
                {event.title !== 'Archive' ? <Col xs="auto" className="text-end">
                    <Icon.Archive
                        onClick={() => onArchive(list)}
                    />
                </Col> : null}
                <Col xs="auto" className="text-end">
                    <Icon.Trash
                        className="text-danger"
                        onClick={() => onDelete(list)}
                    />
                </Col>
            </>
        }
        return view
    }, [
        list.isComplete,
        list.eventId,
        navigate,
        canEdit,
        show,
    ])

    return (
        view
    )
}

function CopyModal({
    list,
    show,
    onHide,
}: {
    list: IShoppingList
    show: boolean
    onHide: () => void
}) {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const [cloneShoppingList, cloneShoppingListResult] = useCloneShoppingListMutation()
    const initState = {
        title: `Clone - ${list.title}`,
    }

    const [initialValues] = useState(initState)

    const addNewList = async (data: AddShoppingListFormData) => {
        try {
            const newList = await cloneShoppingList({ eventId: list.eventId, listId: list._id, title: data.title }).unwrap()
            navigate('/app/events/' + newList.eventId + '/lists/' + newList._id)
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const onSubmit = (values: { title: string }) => {
        addNewList({
            title: values.title,
        })
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        defaultValues: initialValues
    })

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t('addNewShoppingListTitle')}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="title">
                        <Form.Control
                            type="text"
                            placeholder={t('shoppingListTitleInputPlaceholder')}
                            {...register("title", { required: t('titleInputRequiredError') })}
                        />
                        {errors.title && (
                            <Form.Text className="text-danger">
                                {errors.title.message}
                            </Form.Text>
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        {t('closeLabel')}
                    </Button>
                    <Button
                        type='submit'
                        variant="primary"
                        disabled={!isValid || cloneShoppingListResult.isLoading}
                    >
                        {cloneShoppingListResult.isLoading ? <Loading /> : t('addButtonLabel')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}