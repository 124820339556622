import { useEffect } from "react";
import { messaging } from '../../Services/Firebase';
import { getToken } from 'firebase/messaging';
import { useSetUserWebPushMutation } from "../../Store/UserConfig/UserConfig.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Toast/Toast.slice";
import { Config } from "../../Config";

function WebPush() {

    const dispatch = useDispatch<AppDispatch>()
    const [setUserWebPush] = useSetUserWebPushMutation()

    useEffect(() => {
        async function getUserWebPushToken() {
            if (!Config.REACT_APP_VAPIDKEY) {
                return
            }
            try {
                const perm = await Notification.requestPermission()
                if (perm === 'granted') {
                    const token = await getToken(messaging, {
                        vapidKey: Config.REACT_APP_VAPIDKEY,
                    })
                    await setUserWebPush(token).unwrap()
                }
            } catch (err) {
                dispatch(setMessage((err as Error).message))
            }
        }
        getUserWebPushToken()
    }, [dispatch, setUserWebPush]);

    return null
}

export default WebPush