import { useRef, useState } from "react"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { useUploadImageMutation, useUploadImageUrlMutation } from "../../Store/Image/Image.service"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { ApiError } from "../../Services/BaseApi"
import Loading from "../Loading"
import * as Icon from 'react-bootstrap-icons'
import { useTranslation } from "react-i18next"

function ProductImagesUpload({
    onChange,
    disabled,
    imageUrls,
    isLoading,
    onDelete,
}: {
    onChange: (imageUrl: string) => void
    imageUrls?: string[]
    disabled?: boolean
    isLoading: boolean
    onDelete: (imageIndex: number) => void
}) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [error, setError] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [imageBase64, setImageBase64] = useState<{
        base64: string
        mimeType: string
    } | null>(null)

    const [uploadImage, uploadImageResult] = useUploadImageMutation()
    const [uploadImageUrl, uploadImageUrlResult] = useUploadImageUrlMutation()

    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const inputImageUrlRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = async () => {
        const file = inputRef.current?.files ? inputRef.current.files[0] : null
        if (file) {
            setUploadedFileName(file.name);
            const base64 = await convertBase64(file)
            if (base64) {
                setImageBase64({
                    base64: base64.toString(),
                    mimeType: file.type,
                })
            } else {
                setImageBase64(null)
            }
        }
    };

    const convertBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                const result = fileReader.result as string
                if (!result) {
                    resolve('')
                } else {
                    const parts = result.split(';base64,')
                    if (parts.length > 1) {
                        resolve(parts[1]);
                    } else {
                        resolve(result);
                    }
                }
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const onSave = async () => {
        if (imageUrl) {
            try {
                const response = await uploadImageUrl(imageUrl).unwrap()
                onChange(response.imageUrl)
                setImageUrl('')
                if (inputImageUrlRef.current) {
                    inputImageUrlRef.current.value = ''
                }
                setError('')
            } catch (err) {
                const errorMsg = (err as ApiError).data.error
                dispatch(setMessage(errorMsg))
                setError(errorMsg)
            }
        } else {
            if (imageBase64) {
                try {
                    const response = await uploadImage(imageBase64).unwrap()
                    onChange(response.imageUrl)
                    setImageBase64(null)
                    setUploadedFileName(null)
                    setError('')
                } catch (err) {
                    const errorMsg = (err as ApiError).data.error
                    dispatch(setMessage(errorMsg))
                    setError(errorMsg)
                }
            }
        }
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Header>{t('addImageTitle')}</Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="url"
                                    ref={inputImageUrlRef}
                                    placeholder={t('imageUrlPlaceholder')}
                                    value={imageUrl}
                                    onChange={e => setImageUrl(e.target.value)}
                                    disabled={disabled || uploadImageResult.isLoading || uploadImageUrlResult.isLoading}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3 text-center">
                        <Col>{t('orText')}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col className="text-center">
                            <input
                                ref={inputRef}
                                disabled={disabled || uploadImageResult.isLoading || uploadImageUrlResult.isLoading}
                                onChange={handleDisplayFileDetails}
                                className="d-none"
                                type="file"
                            />
                            <div className="d-grid gap-2">
                                <Button
                                    variant={`outline-${uploadedFileName ? "success" : "primary"}`}
                                    disabled={disabled || uploadImageResult.isLoading || uploadImageUrlResult.isLoading}
                                    onClick={handleUpload}
                                >
                                    {uploadedFileName ? uploadedFileName : t('chooseFileLabel')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-grid gap-2">
                                <Button
                                    variant="primary"
                                    disabled={(!imageUrl && !imageBase64) || uploadImageResult.isLoading || uploadImageUrlResult.isLoading}
                                    onClick={onSave}
                                >
                                    {uploadImageResult.isLoading || uploadImageUrlResult.isLoading ? <>{t('pleaseWaitButtonLabel')} <Loading /></> : t('saveButtonLabel')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    {error ? <Row className="mt-3 text-danger">
                        <Col>
                            {error}
                        </Col>
                    </Row> : null}
                </Card.Body>
            </Card>
            <Row xs={1} md={2} className="g-4">
                {imageUrls?.map((imageUrl, idx) => (
                    <ProductImage
                        key={idx}
                        imageIndex={idx}
                        isLoading={isLoading}
                        imageUrl={imageUrl}
                        onDelete={onDelete}
                    />
                ))}
            </Row>
        </>
    )
}

export default ProductImagesUpload

function ProductImage({
    imageIndex,
    imageUrl,
    isLoading,
    onDelete
}: {
    imageIndex: number
    imageUrl: string
    isLoading: boolean
    onDelete: (imageIndex: number) => void
}) {

    return (
        <Col>
            <Card>
                <Card.Img variant="top" src={imageUrl} />
                <Card.Footer className="text-center">
                    {!isLoading ?
                        <Icon.Trash className="text-danger" onClick={() => onDelete(imageIndex)} />
                        : <Loading />}
                </Card.Footer>
            </Card>
        </Col>
    )
}