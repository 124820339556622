import { skipToken } from "@reduxjs/toolkit/query"
import { Badge, Breadcrumb, Button, ButtonGroup, Col, Container, Dropdown, Modal, Offcanvas, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useGetEventQuery } from "../../Store/Event/Event.service"
import { ReactElement, useEffect, useMemo, useState } from "react"
import * as Icon from 'react-bootstrap-icons'
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { ApiError } from "../../Services/BaseApi"
import { currencyCodesType, IEvent, IListProduct, IMember, IReceipt, IShoppingList, Member } from "../../Types"
import EditProduct from "./ProductEdit"
import EventUserAvatars from "../EventUserAvatars"
import TitleButton from "../TitleButton"
import ProductListItem from "../ProductListItem"
import Loading from "../Loading"
import { useAuth0 } from "@auth0/auth0-react"
import { useDeleteListReceiptMutation, useGetShoppingListQuery, useUpdateShoppingListMutation } from "../../Store/ShoppingList/ShoppingList.service"
import { useListListProductsQuery } from "../../Store/ListProduct/ListProduct.service"
import { useGetUserQuery } from "../../Store/UserConfig/UserConfig.service"
import UserAvatar from "../UserAvatar"
import { DateService } from "../../Services/Date"
import { useTranslation } from "react-i18next"
import ImportFreeText from "./import-free-text"
import { CurrencyService } from "../../Services/Currency"
import { NumericFormat } from "react-number-format"
import ReceiptsUpload from "../EditResource/EditReceipts/ReceiptsUpload"

function SLists({ isShared }: { isShared?: boolean }) {

    const { eventId, listId } = useParams()

    let eventArgs: {
        eventId: string
        sharedListId?: string
    } | typeof skipToken = eventId ? { eventId } : skipToken
    if (eventId && listId) {
        eventArgs = {
            eventId,
        }
        if (isShared) {
            eventArgs = {
                eventId,
                sharedListId: listId,
            }
        }
    }
    const eventQuery = useGetEventQuery(eventArgs)

    const listArgs = eventId && listId ? { eventId, listId } : skipToken
    const listQuery = useGetShoppingListQuery(listArgs)
    const productsQuery = useListListProductsQuery(listArgs)

    const [productsComplete, setProductsComplete] = useState(false)
    const [showComplete, setShowComplete] = useState(false)

    const [editProduct, setEditProduct] = useState<IListProduct | null>(null)
    const [showEdit, setShowEdit] = useState(false)

    useEffect(() => {
        if (!productsQuery.isLoading && productsQuery.isSuccess && productsQuery.data) {
            if (editProduct) {
                for (const product of productsQuery.data) {
                    if (product._id === editProduct._id) {
                        setEditProduct({ ...product })
                        break
                    }
                }
            }
            let isComplete = true
            if (productsQuery.data.length) {
                for (const product of productsQuery.data) {
                    if (product.status !== "COMPLETE") {
                        isComplete = false
                        break
                    }
                }
            } else {
                isComplete = false
            }
            setProductsComplete(isComplete)
        }
    }, [
        productsQuery.isLoading,
        productsQuery.isSuccess,
        productsQuery.data,
    ])

    useEffect(() => {
        setShowComplete(false)
        if (!listQuery.isLoading && listQuery.isSuccess && listQuery.data) {
            if (!listQuery.data.isComplete && productsComplete) {
                setShowComplete(true)
            }
        }
    }, [listQuery.isLoading, listQuery.isSuccess, listQuery.data, productsComplete])

    const onEdit = (product: IListProduct) => {
        setEditProduct(product)
        setShowEdit(true)
    }

    return (
        <Container>
            <ShoppingListBreadcrumbs
                event={eventQuery.data}
                list={listQuery.data}
            />
            <ShoppingListTitle list={listQuery.data} />
            <ShoppingListSchedule scheduleAt={listQuery.data?.scheduleAt} />
            <ShoppingListAssignee assigneeUserId={listQuery.data?.assigneeUserId} />
            <ShoppingListMembers eventMembers={eventQuery.data?.members} listMembers={listQuery.data?.members} />
            <ShoppingListReceipts
                eventId={eventQuery.data?._id}
                listId={listQuery.data?._id}
                receipts={listQuery.data?.receipts}
            />
            <ShoppingListDescription list={listQuery.data} />
            <ProductAddButton list={listQuery.data} />
            <ShoppingListProducts
                editProduct={editProduct}
                onEdit={onEdit}
                handleClose={() => {
                    setEditProduct(null)
                    setShowEdit(false)
                }}
                showEdit={showEdit}
                products={productsQuery.data}
            />
            <ShoppingListFinalizeModal
                eventId={eventId}
                listId={listId}
                show={showComplete}
                onHide={() => setShowComplete(false)}
            />
        </Container>
    )
}

export default SLists

function ShoppingListFinalizeModal({ eventId, listId, show, onHide }: { eventId?: string, listId?: string, show: boolean, onHide: () => void }) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [updateShoppingList, updateShoppingListResult] = useUpdateShoppingListMutation()

    const markListComplete = async (isComplete: boolean) => {
        if (!eventId || !listId) {
            return
        }
        try {
            await updateShoppingList({ eventId, listId, isComplete }).unwrap()
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    let view = null
    if (eventId && listId) {
        view = <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t('finalizeShoppingListTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('finalizeShoppingListText')}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    {t('closeLabel')}
                </Button>
                <Button
                    variant="primary"
                    disabled={updateShoppingListResult.isLoading}
                    onClick={() => markListComplete(true)}
                >
                    {updateShoppingListResult.isLoading ? <Loading /> : t('yesButtonLabel')}
                </Button>
            </Modal.Footer>
        </Modal>
    }

    return (
        view
    )
}

function ShoppingListMembers({ eventMembers, listMembers }: { eventMembers?: IMember[], listMembers?: IMember[] }) {

    const { t } = useTranslation()
    const members = useMemo(() => {
        if (!eventMembers?.length && !listMembers?.length) {
            return []
        }

        const allMembers: any = {}
        if (eventMembers?.length) {
            for (const member of eventMembers) {
                if (member.userId) {
                    allMembers[member.userId] = member
                }
            }
        }
        if (listMembers?.length) {
            for (const member of listMembers) {
                if (member.userId) {
                    allMembers[member.userId] = member
                }
            }
        }
        let allMembersValues: Member[] = []
        if (Object.keys(allMembers).length > 0) {
            allMembersValues = Object.values(allMembers)
        }
        const members = allMembersValues.map(member => {
            return {
                userId: member.userId,
                email: member.email,
                name: member.name,
                picture: member.picture,
                isMe: false,
            }
        }) as Member[]

        return members
    }, [
        eventMembers,
        listMembers,
    ])

    let view = null
    if (members.length) {
        view = <Row className="mb-1">
            <Col xs="auto">
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={props => (
                        <Tooltip id="button-tooltip" {...props}>
                            {t('membersTitle')}
                        </Tooltip>
                    )}
                >
                    <Icon.People />
                </OverlayTrigger>
            </Col>
            <Col><EventUserAvatars users={members.map(member => member)} /></Col>
        </Row>
    }

    return (
        view
    )
}

function ShoppingListReceipts({
    eventId,
    listId,
    receipts,
}: {
    eventId?: string
    listId?: string
    receipts?: IReceipt[]
}) {

    const { t } = useTranslation()

    const [show, setShow] = useState(false)
    const dispatch = useDispatch<AppDispatch>()

    const onHide = () => setShow(false)
    const [updateShoppingList, updateShoppingListResult] = useUpdateShoppingListMutation()
    const [deleteListReceipt, deleteListReceiptResult] = useDeleteListReceiptMutation()

    const handleUpdate = async (value: IReceipt, key: "receipt") => {
        if (!eventId || !listId) {
            return
        }
        try {
            const q: { [index: string]: IReceipt } = {}
            q[key] = value
            await updateShoppingList({ eventId, listId, ...q }).unwrap()
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    const onDeleteReceipt = async (receiptIndex: number) => {
        if (!eventId || !listId) {
            return
        }
        const confirm = window.confirm(`Delete receipt?`)
        if (confirm) {
            try {
                await deleteListReceipt({
                    eventId,
                    listId,
                    receiptIndex,
                }).unwrap()
                dispatch(setMessage(t('receiptRemovedNotification')))
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        }
    }

    const totals: { code: currencyCodesType, amount?: number }[] = useMemo(() => {
        const totalsIndex: { [key in currencyCodesType]?: number } = {}
        const totals: { code: currencyCodesType, amount?: number }[] = []
        if (receipts?.length) {
            for (const receipt of receipts) {
                const code = receipt.currencyCode
                if (undefined === totalsIndex[code]) {
                    totalsIndex[code] = 0
                }
                if (undefined !== totalsIndex[code]) {
                    totalsIndex[code] = (totalsIndex[code] ?? 0) + receipt.amount
                }
            }
            const keys = Object.keys(totalsIndex)
            if (keys.length) {
                for (const key of keys) {
                    const indexKey = key as currencyCodesType
                    totals.push({
                        code: indexKey,
                        amount: totalsIndex[indexKey],
                    })
                }
            }
        }
        return totals
    }, [
        receipts,
    ])

    let view = <Row className="mb-1">
        <Col xs="auto">
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={props => (
                    <Tooltip id="button-tooltip" {...props}>
                        {t('receiptsLabel')}
                    </Tooltip>
                )}
            >
                <Icon.Cash />
            </OverlayTrigger>
        </Col>
        <Col>
            <NumericFormat
                value={0}
                displayType="text"
                thousandSeparator={true}
                fixedDecimalScale={true}
                decimalScale={2}
            />
        </Col>
        <Col xs="auto">
            <Button
                variant="link"
                size="sm"
                onClick={() => setShow(true)}
            ><Icon.Eye /> {t('viewButtonLabel')}</Button>
            <Offcanvas show={show} onHide={onHide}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t('uploadReceiptsTitle')}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ReceiptsUpload
                        disabled={updateShoppingListResult.isLoading}
                        onChange={receipt => handleUpdate(receipt, "receipt")}
                        receipts={receipts}
                        isLoading={deleteListReceiptResult.isLoading}
                        onDelete={onDeleteReceipt}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </Col>
    </Row>
    if (receipts?.length) {
        view = <Row className="mb-1">
            <Col xs="auto">
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={props => (
                        <Tooltip id="button-tooltip" {...props}>
                            {t('receiptsLabel')}
                        </Tooltip>
                    )}
                >
                    <Icon.Cash />
                </OverlayTrigger>
            </Col>
            <Col>
                {totals.map((total, idx) => {
                    let symbol = '$'
                    if (total.code) {
                        const currency = CurrencyService.getCurrency(total.code)
                        if (currency) {
                            symbol = currency.symbol
                        }
                    }
                    return (
                        <span key={idx}>
                            {idx > 0 ? ', ' : ''}
                            <NumericFormat
                                value={total.amount}
                                displayType="text"
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={symbol}
                            />
                        </span>
                    )
                })}
            </Col>
            <Col xs="auto">
                <Button
                    variant="link"
                    size="sm"
                    onClick={() => setShow(true)}
                ><Icon.Eye /> {t('viewButtonLabel')}</Button>
                <Offcanvas show={show} onHide={onHide}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{t('uploadReceiptsTitle')}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <ReceiptsUpload
                            disabled={updateShoppingListResult.isLoading}
                            onChange={receipt => handleUpdate(receipt, "receipt")}
                            receipts={receipts}
                            isLoading={deleteListReceiptResult.isLoading}
                            onDelete={onDeleteReceipt}
                        />
                    </Offcanvas.Body>
                </Offcanvas>
            </Col>
        </Row>
    }

    return (
        view
    )
}

function ProductAddButton({ list }: { list?: IShoppingList }) {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [show, setShow] = useState(false)

    let view = null
    if (list) {
        view = <Row>
            <Col>
                <div className="d-grid gap-2 mb-3">
                    <Dropdown as={ButtonGroup} dir="ltr">
                        <Button
                            variant="primary"
                            onClick={() => navigate(`/app/events/${list.eventId}/lists/${list._id}/products-add`)}
                        ><Icon.Plus /> {t('addProductButtonLabel')}</Button>
                        <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setShow(true)}>{t('importFreeTextLabel')}</Dropdown.Item>
                            <Dropdown.Item onClick={() => navigate(`/app/events/${list.eventId}/lists/${list._id}/products-barcode-scan`)}>{t('scanBarcodeTitle')}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <ImportFreeText
                    show={show}
                    onHide={() => setShow(false)}
                    list={list}
                />
            </Col>
        </Row>
    }

    return (
        view
    )
}

function ShoppingListTitle({ list }: { list?: IShoppingList }) {

    const { t } = useTranslation()
    const navigate = useNavigate()

    let view = null
    if (list) {
        view = <TitleButton
            title={<>{list.title}{list.isComplete ? <Badge bg="success" pill className="ms-1">{t('completeLabel')}</Badge> : null}</>}
            buttonText={<><Icon.Pencil /> {t('editButtonLabel')}</>}
            onClick={() => navigate(`/app/events/${list.eventId}/lists-edit/${list._id}?r=/app/events/${list.eventId}/lists/${list._id}`)}
        />
    }

    return (
        view
    )
}

function ShoppingListDescription({ list }: { list?: IShoppingList }) {

    let view = null
    if (list) {
        view = <Row className="mb-3">
            <Col className="small">
                {list.description}
            </Col>
        </Row>
    }

    return (
        view
    )
}

function ShoppingListBreadcrumbs({ event, list }: { event?: IEvent, list?: IShoppingList }) {

    const { t } = useTranslation()
    const navigate = useNavigate()

    let view = null
    if (event && list) {
        view = <Row>
            <Col>
                <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate('/app')}>{t('homeTitle')}</Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => navigate('/app/events')}>{t('eventsTitle')}</Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => navigate(`/app/events/${event._id}`)}>{event.title}</Breadcrumb.Item>
                    <Breadcrumb.Item active>{list.title}</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
        </Row>
    }

    return (
        view
    )
}

function ShoppingListProducts({
    editProduct,
    showEdit,
    products,
    handleClose,
    onEdit,
}: {
    editProduct: IListProduct | null
    showEdit: boolean
    products?: IListProduct[]
    handleClose: () => void
    onEdit: (product: IListProduct) => void
}) {

    const { t } = useTranslation()

    let view = <Row>
        <Col>
            {t('noProductsText')}
        </Col>
    </Row>
    if (products?.length) {
        view = <Row>
            <Col>
                {editProduct ? <EditProduct
                    show={showEdit}
                    handleClose={handleClose}
                    product={editProduct}
                /> : null}

                {products.map(product => <ProductListItem
                    product={product}
                    key={product._id}
                    onEdit={onEdit}
                />)}
            </Col>
        </Row>
    }

    return (
        view
    )
}

function ShoppingListAssignee({ assigneeUserId }: { assigneeUserId?: string }) {

    const { t } = useTranslation()
    const args = assigneeUserId ?? skipToken
    const userQuery = useGetUserQuery(args)

    const [view, setView] = useState<ReactElement | null>(null)

    useEffect(() => {
        if (userQuery.isSuccess && !userQuery.isLoading && userQuery.data) {
            const _view = <Row className="mb-1">
                <Col xs="auto">
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={props => (
                            <Tooltip id="button-tooltip" {...props}>
                                {t('assigneeTitle')}
                            </Tooltip>
                        )}
                    >
                        <Icon.Person />
                    </OverlayTrigger>
                </Col>
                <Col><UserAvatar user={userQuery.data} showName={true} /></Col>
            </Row>
            setView(_view)
        }
    }, [
        userQuery.isSuccess,
        userQuery.isLoading,
        userQuery.data,
        t,
    ])

    return (
        view
    )
}

function ShoppingListSchedule({ scheduleAt }: { scheduleAt?: string | Date }) {

    let view = null
    if (scheduleAt) {
        view = <Row>
            <Col xs="auto"><Icon.Clock /></Col>
            <Col className="small">
                {DateService.formatDate(scheduleAt, 'LLLL')}
            </Col>
        </Row>
    }

    return (
        view
    )
}