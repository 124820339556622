import { useMemo, useRef, useState } from "react"
import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../Store/Store"
import { setMessage } from "../../../../Store/Toast/Toast.slice"
import { ApiError } from "../../../../Services/BaseApi"
import Loading from "../../../Loading"
import * as Icon from 'react-bootstrap-icons'
import { useTranslation } from "react-i18next"
import { currencyCodes, currencyCodesType, IReceipt, langCodes, langCodesType } from "../../../../Types"
import { useUploadImageMutation, useUploadImageUrlMutation } from "../../../../Store/Image/Image.service"
import { CurrencyService } from "../../../../Services/Currency"
import Receipt from "../../../Receipt"

function ReceiptsUpload({
    onChange,
    disabled,
    receipts,
    isLoading,
    onDelete,
}: {
    onChange: (receipt: IReceipt) => void
    receipts?: IReceipt[]
    disabled?: boolean
    isLoading: boolean
    onDelete: (receiptIndex: number) => void
}) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()

    const defaultCurrencyCode = useSelector((state: RootState) => state.userConfig.currencyCode)
    const defaultLangCode = useSelector((state: RootState) => state.userConfig.langCode)

    const [error, setError] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [imageBase64, setImageBase64] = useState<{
        base64: string
        mimeType: string
    } | null>(null)

    const [amount, setAmount] = useState(0)
    const [currencyCode, setCurrencyCode] = useState(defaultCurrencyCode)
    const [showCurrencyPicker, setShowCurrencyPicker] = useState(false)
    const [langCode, setLangCode] = useState(defaultLangCode)

    const [uploadImage, uploadImageResult] = useUploadImageMutation()
    const [uploadImageUrl, uploadImageUrlResult] = useUploadImageUrlMutation()

    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

    const inputRef = useRef<HTMLInputElement>(null);
    const inputImageUrlRef = useRef<HTMLInputElement>(null);
    const inputAmountRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = async () => {
        const file = inputRef.current?.files ? inputRef.current.files[0] : null
        if (file) {
            setUploadedFileName(file.name);
            const base64 = await convertBase64(file)
            if (base64) {
                setImageBase64({
                    base64: base64.toString(),
                    mimeType: file.type,
                })
            } else {
                setImageBase64(null)
            }
        }
    };

    const convertBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                const result = fileReader.result as string
                if (!result) {
                    resolve('')
                } else {
                    const parts = result.split(';base64,')
                    if (parts.length > 1) {
                        resolve(parts[1]);
                    } else {
                        resolve(result);
                    }
                }
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const onSave = async () => {
        if (imageUrl) {
            try {
                const response = await uploadImageUrl(imageUrl).unwrap()
                onChange({
                    amount,
                    currencyCode,
                    imageUrl: response.imageUrl,
                    langCode,
                })
                setImageUrl('')
                if (inputImageUrlRef.current) {
                    inputImageUrlRef.current.value = ''
                }
                if (inputAmountRef.current) {
                    inputAmountRef.current.value = ''
                }
                setError('')
            } catch (err) {
                const errorMsg = (err as ApiError).data.error
                dispatch(setMessage(errorMsg))
                setError(errorMsg)
            }
        } else {
            if (imageBase64) {
                try {
                    const response = await uploadImage(imageBase64).unwrap()
                    onChange({
                        amount,
                        currencyCode,
                        imageUrl: response.imageUrl,
                        langCode,
                    })
                    setImageBase64(null)
                    setUploadedFileName(null)
                    setError('')
                    if (inputAmountRef.current) {
                        inputAmountRef.current.value = ''
                    }
                } catch (err) {
                    const errorMsg = (err as ApiError).data.error
                    dispatch(setMessage(errorMsg))
                    setError(errorMsg)
                }
            } else {
                onChange({
                    amount,
                    currencyCode,
                    langCode,
                })
                if (inputAmountRef.current) {
                    inputAmountRef.current.value = ''
                }
            }
        }
    }

    const currencyIcon = useMemo(() => {
        let icon = <Icon.CurrencyDollar />
        if (currencyCode) {
            const symbol = CurrencyService.getCurrency(currencyCode)?.symbol
            if (symbol) {
                icon = <>{symbol}</>
            }
        }
        return icon
    }, [
        currencyCode,
    ])

    return (
        <>
            <Card className="mb-3">
                <Card.Header>{t('addReceiptTitle')}</Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>{t('receiptAmountLabel')}</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text
                                        id="basic-addon2"
                                        onClick={() => setShowCurrencyPicker(true)}
                                    >
                                        {currencyIcon}
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        placeholder={t('amountLabel')}
                                        ref={inputAmountRef}
                                        defaultValue={amount}
                                        onChange={e => setAmount(+e.target.value)}
                                        disabled={disabled}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <CurrencyModal
                                show={showCurrencyPicker}
                                onHide={() => setShowCurrencyPicker(false)}
                                onSelect={code => {
                                    setShowCurrencyPicker(false)
                                    setCurrencyCode(code)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>{t('receiptLangLabel')}</Form.Label>
                                <Form.Select
                                    value={langCode}
                                    onChange={(e) => setLangCode(e.target.value as langCodesType)}
                                >
                                    {langCodes.map(code => {
                                        const c = CurrencyService.getLangNameByCode(code)
                                        if (!c) {
                                            return null
                                        }
                                        return (
                                            <option key={code} value={code}>{c}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>{t('receiptImageLabel')}</Form.Label>
                                <Form.Control
                                    type="url"
                                    ref={inputImageUrlRef}
                                    placeholder={t('imageUrlPlaceholder')}
                                    value={imageUrl}
                                    onChange={e => setImageUrl(e.target.value)}
                                    disabled={disabled || uploadImageResult.isLoading || uploadImageUrlResult.isLoading}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3 text-center">
                        <Col>{t('orText')}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col className="text-center">
                            <input
                                ref={inputRef}
                                disabled={disabled || uploadImageResult.isLoading || uploadImageUrlResult.isLoading}
                                onChange={handleDisplayFileDetails}
                                className="d-none"
                                type="file"
                            />
                            <div className="d-grid gap-2">
                                <Button
                                    variant={`outline-${uploadedFileName ? "success" : "primary"}`}
                                    disabled={disabled || uploadImageResult.isLoading || uploadImageUrlResult.isLoading}
                                    onClick={handleUpload}
                                >
                                    {uploadedFileName ? uploadedFileName : t('chooseFileLabel')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="d-grid gap-2">
                                <Button
                                    variant="primary"
                                    disabled={amount <= 0 || uploadImageResult.isLoading || uploadImageUrlResult.isLoading}
                                    onClick={onSave}
                                >
                                    {uploadImageResult.isLoading || uploadImageUrlResult.isLoading ? <>{t('pleaseWaitButtonLabel')} <Loading /></> : t('addButtonLabel')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    {error ? <Row className="mt-3 text-danger">
                        <Col>
                            {error}
                        </Col>
                    </Row> : null}
                </Card.Body>
            </Card>
            <Row xs={1} md={2} className="g-4">
                {receipts?.map((receipt, idx) => <Col key={idx}>
                    <Receipt
                        receiptIndex={idx}
                        isLoading={isLoading}
                        receipt={receipt}
                        onDelete={onDelete}
                    />
                </Col>)}
            </Row>
        </>
    )
}

export default ReceiptsUpload

function CurrencyModal({ show, onHide, onSelect }: { show: boolean, onHide: () => void, onSelect: (code: currencyCodesType) => void }) {

    const { t } = useTranslation()

    const currencyCode = useSelector((state: RootState) => state.userConfig.currencyCode)
    const [code, setCode] = useState<currencyCodesType>(currencyCode)

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t('addNewProductTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Select
                    value={code}
                    onChange={(e) => setCode(e.target.value as currencyCodesType)}
                >
                    {currencyCodes.map(code => {
                        const c = CurrencyService.getCurrency(code)
                        if (!c) {
                            return null
                        }
                        return (
                            <option key={c.code} value={c.code}>{c?.name}</option>
                        )
                    })}
                </Form.Select>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    {t('closeLabel')}
                </Button>
                <Button
                    variant="primary"
                    onClick={() => onSelect(code)}
                >
                    {t('addButtonLabel')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}