import { skipToken } from "@reduxjs/toolkit/query"
import { Breadcrumb, Col, Container, ListGroup, Row } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useGetSharedEventQuery } from "../../Store/Event/Event.service"
import EventUserAvatars from "../EventUserAvatars"
import ShoppingListItem from "../ShoppingListItem"
import { useMemo } from "react"
import { Member } from "../../Types"
import { useListSharedShoppingListsQuery } from "../../Store/ShoppingList/ShoppingList.service"
import { useTranslation } from "react-i18next"

function SharedEvent() {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { eventId } = useParams()

    const args = eventId ?? skipToken
    const eventQuery = useGetSharedEventQuery(args)

    const listsQuery = useListSharedShoppingListsQuery(args)

    const members = useMemo(() => {
        if (eventQuery.isLoading || !eventQuery.isSuccess || !eventQuery.data?.members?.length) {
            return []
        }
        const list = eventQuery.data
        const members = list.members?.filter(member => !!member.userId).map(member => {
            return {
                userId: member.userId,
                email: member.email,
                name: member.name,
                picture: member.picture,
                isMe: false,
            }
        }) as Member[]

        return members
    }, [eventQuery.isLoading, eventQuery.isSuccess, eventQuery.data])

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app')}>{t('homeTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/shared-events')}>{t('sharedEventsTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{eventQuery.data?.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>{eventQuery.data?.title}</h1>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    {members.length ? <EventUserAvatars users={members} /> : null}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <h3>{t('shoppingListsTitle')}</h3>
                </Col>
            </Row>
            {eventId && eventQuery.data ? <Row>
                <Col>
                    <ListGroup>
                        {listsQuery.data?.map(list => {
                            let view = null
                            if (eventQuery.data) {
                                view = <ShoppingListItem
                                    key={list._id}
                                    list={list}
                                    event={eventQuery.data}
                                    isShared={true}
                                    canEdit={false}
                                />
                            }
                            return (
                                view
                            )
                        })}
                    </ListGroup>
                </Col>
            </Row> : null}
        </Container>
    )
}

export default SharedEvent