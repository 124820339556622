import { useAuth0 } from "@auth0/auth0-react";
import { Button, Col, Container, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import logo from '../../Images/logo.png'

function Welcome() {

    const navigate = useNavigate()
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    let view = <Container>
        <Row className="text-center my-3">
            <Col>
                <img
                    alt="Shopping List"
                    src={logo}
                    height="30"
                    className="d-inline-block align-top"
                />
            </Col>
        </Row>
        <Row className="text-center">
            <Col><h1>Simplify Your Shopping. Organize Your Life.</h1></Col>
        </Row>
        <Row className="mb-2 text-center">
            <Col>Create, manage, and share personalized shopping lists with ease.</Col>
        </Row>
        <Row className="mb-2 text-center">
            <Col>Tired of forgetting items on your grocery list? Looking for a way to collaborate with friends and family on group shopping trips? Our app is here to simplify your shopping experience.</Col>
        </Row>
        <Row className="mb-2">
            <Col>
                <div>Key Features:</div>
                <ul>
                    <li><b>Personalized Shopping Lists:</b> Create custom lists tailored to your needs and preferences.</li>
                    <li><b>Collaboration:</b> Share lists with friends and family for group shopping or meal planning.</li>
                    <li><b>Reminders:</b> Set reminders to stay on top of your shopping needs.</li>
                    <li><b>Store Finder:</b> Discover the best stores near you to find your desired products.</li>
                    <li><b>Easy-to-Use Interface:</b> Enjoy a user-friendly design that makes shopping a breeze.</li>
                </ul>
            </Col>
        </Row>
        <Row className="mb-1 text-center">
            <Col>Start Shopping Smarter Today!</Col>
        </Row>
        <Row>
            <Col className="text-center">
                <Button
                    variant="primary"
                    onClick={() => {
                        isAuthenticated ? navigate('/app') : loginWithRedirect()
                    }}
                >
                    Get Started
                </Button>
            </Col>
        </Row>
    </Container>

    return view
}

export default Welcome