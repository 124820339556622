import { baseSplitApi } from "../../Services/BaseApi"
import { IListProduct, IProduct } from "../../Types"
import { AddListProductPayload, ImportFreeTextListProductPayload, UpdateListProductPayload } from "./Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listListProducts: build.query<IListProduct[], { eventId: string, listId: string }>({
            query: ({ eventId, listId }) => '/events/' + eventId + '/lists/' + listId + '/list-products',
            providesTags: ["ListProducts"]
        }),
        getListProduct: build.query<IListProduct, { eventId: string, listId: string, productId: string }>({
            query: ({ eventId, listId, productId }) => '/events/' + eventId + '/lists/' + listId + '/list-products/' + productId,
        }),
        addListProduct: build.mutation<IListProduct, AddListProductPayload>({
            query: (payload) => ({
                url: '/events/' + payload.eventId + '/lists/' + payload.listId + '/list-products',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["ListProducts", "RecentProducts"]
        }),
        importFreeTextListProducts: build.mutation<{ name: string, qty?: number, products?: IProduct[] }[], ImportFreeTextListProductPayload>({
            query: ({ eventId, listId, text }) => ({
                url: '/events/' + eventId + '/lists/' + listId + '/list-products/import-free-text',
                method: 'POST',
                body: { text },
            }),
            invalidatesTags: ["ListProducts", "RecentProducts"]
        }),
        moveListProduct: build.mutation<void, { eventId: string, listId: string, productId: string, targetListId: string }>({
            query: ({ eventId, listId, productId, targetListId }) => ({
                url: '/events/' + eventId + '/lists/' + listId + '/list-products/' + productId + '/move',
                method: 'POST',
                body: { targetListId },
            }),
            invalidatesTags: ["ListProducts", "RecentProducts"]
        }),
        deleteListProduct: build.mutation<void, { eventId: string, listId: string, productId: string }>({
            query: ({ eventId, listId, productId }) => ({
                url: '/events/' + eventId + '/lists/' + listId + '/list-products/' + productId,
                method: 'DELETE',
            }),
            invalidatesTags: ["ListProducts"]
        }),
        updateListProduct: build.mutation<void, UpdateListProductPayload>({
            query: (body) => ({
                url: '/events/' + body.eventId + '/lists/' + body.listId + '/list-products/' + body.productId,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ["ListProducts"]
        }),
        deleteListProductImage: build.mutation<void, { eventId: string, listId: string, productId: string, imageIndex: number }>({
            query: ({ eventId, listId, productId, imageIndex }) => ({
                url: '/events/' + eventId + '/lists/' + listId + '/list-products/' + productId + '/image',
                method: 'DELETE',
                body: { imageIndex },
            }),
            invalidatesTags: ["ListProducts"]
        }),
        markListProductDone: build.mutation<void, { eventId: string, listId: string, ids: string[], isDone: boolean }>({
            query: ({ eventId, listId, ids, isDone }) => ({
                url: '/events/' + eventId + '/lists/' + listId + `/list-products${isDone ? '?isDone=1' : ''}`,
                method: 'PUT',
                body: ids,
            }),
            invalidatesTags: ["ListProducts"]
        }),
        listSharedListProducts: build.query<IListProduct[], { eventId: string, listId: string }>({
            query: ({ eventId, listId }) => '/shared-events/' + eventId + '/shared-lists/' + listId + '/shared-list-products',
            providesTags: ["SharedListProducts"]
        }),
        addSharedListProduct: build.mutation<IListProduct, AddListProductPayload>({
            query: (payload) => ({
                url: '/shared-events/' + payload.eventId + '/shared-lists/' + payload.listId + '/shared-list-products',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["SharedListProducts"]
        }),
        deleteSharedListProduct: build.mutation<void, { eventId: string, listId: string, productId: string }>({
            query: ({ eventId, listId, productId }) => ({
                url: '/shared-events/' + eventId + '/shared-lists/' + listId + '/shared-list-products/' + productId,
                method: 'DELETE',
            }),
            invalidatesTags: ["SharedListProducts"]
        }),
        updateSharedListProduct: build.mutation<void, UpdateListProductPayload>({
            query: (body) => ({
                url: '/shared-events/' + body.eventId + '/shared-lists/' + body.listId + '/shared-list-products/' + body.productId,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ["SharedListProducts"]
        }),
        markSharedListProductDone: build.mutation<void, { eventId: string, listId: string, ids: string[], isDone: boolean }>({
            query: ({ eventId, listId, ids, isDone }) => ({
                url: '/shared-events/' + eventId + '/shared-lists/' + listId + `/shared-list-products${isDone ? '?isDone=1' : ''}`,
                method: 'PUT',
                body: ids,
            }),
            invalidatesTags: ["SharedListProducts"]
        }),
    }),
    overrideExisting: false,
})

export const {
    useListListProductsQuery,
    useGetListProductQuery,
    useAddListProductMutation,
    useImportFreeTextListProductsMutation,
    useMoveListProductMutation,
    useDeleteListProductMutation,
    useDeleteListProductImageMutation,
    useUpdateListProductMutation,
    useMarkListProductDoneMutation,
    useListSharedListProductsQuery,
    useAddSharedListProductMutation,
    useUpdateSharedListProductMutation,
    useDeleteSharedListProductMutation,
    useMarkSharedListProductDoneMutation,
} = api