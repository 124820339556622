import { baseSplitApi } from "../../Services/BaseApi"
import { IEvent, IEventInvitation, IShoppingList, SharedListsEvents } from "../../Types"
import { AddEventInvitationPayload, AddEventPayload, UpdateEventPayload } from "./Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listEvents: build.query<IEvent[], void>({
            query: () => '/events',
            providesTags: ["Events"]
        }),
        getEvent: build.query<IEvent, { eventId: string, sharedListId?: string }>({
            query: ({ eventId, sharedListId }) => '/events/' + eventId + (sharedListId ? '?sharedListId=' + sharedListId : ''),
            providesTags: ["Events"]
        }),
        addEvent: build.mutation<IEvent, AddEventPayload>({
            query: (payload) => ({
                url: '/events',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["Events"]
        }),
        updateEvent: build.mutation<void, UpdateEventPayload>({
            query: (payload) => ({
                url: '/events/' + payload.eventId,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ["Events"]
        }),
        deleteEvent: build.mutation<void, { eventId: string }>({
            query: ({ eventId }) => ({
                url: '/events/' + eventId,
                method: 'DELETE',
            }),
            invalidatesTags: ["Events"]
        }),
        addEventInvitation: build.mutation<IEventInvitation, AddEventInvitationPayload>({
            query: (payload) => ({
                url: '/event-invitations',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["Events", "InvitationUsers"]
        }),
        deleteEventInvitation: build.mutation<IEventInvitation, { eventId: string, email: string }>({
            query: (body) => ({
                url: '/event-invitations',
                method: 'DELETE',
                body,
            }),
            invalidatesTags: ["Events", "InvitationUsers"]
        }),
        validateEventInvitation: build.query<{ eventId: string, title: string, status: "PENDING" | "ACCEPTED", inviterName: string }, string>({
            query: (token) => '/event-invitations-validation/' + token,
        }),
        acceptEventInvitation: build.mutation<void, string>({
            query: (token) => '/event-invitations-accept/' + token,
            invalidatesTags: ["EventInvitations", "InvitationUsers"]
        }),
        listSharedEvents: build.query<(IEvent & { lists: IShoppingList[] })[], void>({
            query: () => '/shared-events',
            providesTags: ["SharedEvents"]
        }),
        getSharedEvent: build.query<IEvent, string>({
            query: (eventId) => '/shared-events/' + eventId,
            providesTags: ["SharedEvents"]
        }),
        listSharedEventLists: build.query<SharedListsEvents, void>({
            query: () => '/shared-event-lists',
        }),
    }),
    overrideExisting: false,
})

export const {
    useListEventsQuery,
    useGetEventQuery,
    useAddEventMutation,
    useUpdateEventMutation,
    useDeleteEventMutation,
    useAddEventInvitationMutation,
    useDeleteEventInvitationMutation,
    useValidateEventInvitationQuery,
    useAcceptEventInvitationMutation,
    useListSharedEventsQuery,
    useGetSharedEventQuery,
    useListSharedEventListsQuery,
} = api