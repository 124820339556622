import { useNavigate } from "react-router-dom"
import { Button, Card, Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import EventUserAvatars from "../EventUserAvatars"
import * as Icon from 'react-bootstrap-icons'
import { currencyCodesType, IEvent, IShoppingList, Member } from "../../Types"
import { ReactElement, useEffect, useMemo, useState } from "react"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { ApiError } from "../../Services/BaseApi"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { useDeleteEventMutation } from "../../Store/Event/Event.service"
import { useTranslation } from "react-i18next"
import { useGetUserQuery } from "../../Store/UserConfig/UserConfig.service"
import { skipToken } from "@reduxjs/toolkit/query"
import UserAvatar from "../UserAvatar"
import { useAuth0 } from "@auth0/auth0-react"
import { NumericFormat } from "react-number-format"
import { CurrencyService } from "../../Services/Currency"
import UserName from "../UserName"
import { useListShoppingListsQuery } from "../../Store/ShoppingList/ShoppingList.service"

function EventListItem({ event, canEdit, isShared }: { event: IEvent, canEdit: boolean, isShared: boolean }) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const [deleteEvent, deleteEventResult] = useDeleteEventMutation()

    const members = useMemo(() => {
        let members: Member[] = []
        if (event.members?.length) {
            members = event.members?.filter(member => !!member.userId).map(member => {
                return {
                    userId: member.userId,
                    email: member.email,
                    name: member.name,
                    picture: member.picture,
                    isMe: false,
                }
            }) as Member[]
        }

        return members
    }, [event.members])

    const onDelete = async (event: IEvent) => {
        if (deleteEventResult.isLoading) {
            return
        }
        try {
            const confirm = window.confirm(t('deleteEventConfirmationText', { eventTitle: event.title }))
            if (confirm) {
                await deleteEvent({ eventId: event._id }).unwrap()
                dispatch(setMessage(t('deleteEventNotification')))
                navigate(`/app/events`)
            }
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    return (
        <Card
            className="mb-3"
        >
            {event.imageUrl ? <Card.Img
                onClick={() => navigate(`/app/events/${event._id}`)}
                src={event.imageUrl}
            /> : null}
            <Card.Body>
                <Card.Title
                    onClick={() => navigate(`/app/events/${event._id}`)}
                >{event.title}</Card.Title>
                <EventOnwer event={event} />
                <EventMembers event={event} />
                {event.description ? <Row>
                    <Col className="small">{event.description}</Col>
                </Row> : null}
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col></Col>
                    {canEdit ? <Col xs="auto" className="text-end">
                        <Icon.Pencil
                            onClick={() => {
                                navigate(`/app/events-edit/${event._id}?r=/app/events`)
                            }}
                        />
                    </Col> : null}
                    {canEdit ? <Col xs="auto" className="text-end">
                        <Icon.Trash
                            className="text-danger"
                            onClick={() => onDelete(event)}
                        />
                    </Col> : null}
                </Row>
            </Card.Footer>
        </Card>
    )
}

export default EventListItem

function EventOnwer({ event }: { event: IEvent }) {

    const { t } = useTranslation()
    const args = event.userId ?? skipToken
    const userQuery = useGetUserQuery(args)

    const listsArgs = event.members?.length ? skipToken : event._id
    const listsQuery = useListShoppingListsQuery(listsArgs)

    const [view, setView] = useState<ReactElement | null>(null)

    useEffect(() => {
        if (userQuery.isSuccess && !userQuery.isLoading && userQuery.data) {
            const _view = <Row className="mb-1">
                <Col xs="auto">
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={props => (
                            <Tooltip id="button-tooltip" {...props}>
                                {t('assigneeTitle')}
                            </Tooltip>
                        )}
                    >
                        <Icon.Person />
                    </OverlayTrigger>
                </Col>
                <Col><UserAvatar user={userQuery.data} showName={true} /></Col>
                {listsQuery.data?.length ? <Col xs="auto"><Split lists={listsQuery.data} /></Col> : null}
            </Row>
            setView(_view)
        }
    }, [
        userQuery.isSuccess,
        userQuery.isLoading,
        userQuery.data,
        t,
    ])

    return (
        view
    )
}

function EventMembers({
    event,
}: {
    event: IEvent
}) {

    const { t } = useTranslation()
    const listsQuery = useListShoppingListsQuery(event._id)

    const members = useMemo(() => {
        let members: Member[] = []
        if (event.members?.length) {
            members = event.members?.filter(member => !!member.userId).map(member => {
                return {
                    userId: member.userId,
                    email: member.email,
                    name: member.name,
                    picture: member.picture,
                    isMe: false,
                }
            }) as Member[]
        }

        return members
    }, [event.members])

    let view = null
    if (members.length) {
        view = <Row className="mb-1">
            <Col xs="auto">
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={props => (
                        <Tooltip id="button-tooltip" {...props}>
                            {t('membersTitle')}
                        </Tooltip>
                    )}
                >
                    <Icon.People />
                </OverlayTrigger>
            </Col>
            <Col>
                <EventUserAvatars users={members} />
            </Col>
            {listsQuery.data?.length ? <Col xs="auto"><Split lists={listsQuery.data} /></Col> : null}
        </Row>
    }

    return (
        view
    )
}

function Split({
    lists,
}: {
    lists: IShoppingList[]
}) {

    const { t } = useTranslation()
    const { user } = useAuth0();
    const [show, setShow] = useState(false)

    const totals = useMemo(() => {
        const table: {
            userId: string
            currencies: {
                [index: string]: number;
            }
        }[] = []
        const totals: {
            [index: string]: {
                [index: string]: number
            }
        } = {}
        if (lists.length) {
            for (const list of lists) {
                if (list.receipts?.length) {
                    const userId = list.assigneeUserId ?? list.userId
                    for (const receipt of list.receipts) {
                        if (!totals[userId]) {
                            totals[userId] = {}
                        }
                        if (!totals[userId][receipt.currencyCode]) {
                            totals[userId][receipt.currencyCode] = 0
                        }
                        totals[userId][receipt.currencyCode] += receipt.amount
                    }
                }
            }
            const userIds = Object.keys(totals)
            if (userIds.length) {
                for (const userId of userIds) {
                    table.push({ userId, currencies: totals[userId] })
                }
            }
        }

        return table
    }, [
        lists,
    ])

    const onHide = () => {
        setShow(false)
    }

    return (
        <>
            <Button
                variant="link"
                size="sm"
                onClick={() => setShow(true)}
            >{t('splitTitle')}</Button>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('eventSplitTitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {totals.length ? totals.map((total, idx) => <Row key={idx}>
                        <Col>{user && total.userId === user.sub ? 'You' : <UserName userId={total.userId} />}</Col>
                        <Col xs="auto">{Object.keys(total.currencies).map(currencyCode => {
                            const c = CurrencyService.getCurrency(currencyCode as currencyCodesType)
                            let symbol = '$'
                            if (c) {
                                symbol = c.symbol
                            }
                            return (
                                <NumericFormat
                                    key={idx}
                                    value={total.currencies[currencyCode]}
                                    displayType="text"
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={symbol}
                                />
                            )
                        })}</Col>
                    </Row>) : <Row>
                        <Col>
                            {t('receiptsNotFoundText')}
                        </Col>
                    </Row>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        {t('closeLabel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}