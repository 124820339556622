import { Col, Offcanvas, Row } from "react-bootstrap"
import { ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"
import { IReceipt } from "../../../Types"
import ReceiptsUpload from "./ReceiptsUpload"

function EditReceipts({
    receipts,
    placeholder,
    icon,
    disabled,
    isLoading,
    handleUpdate,
    onDeleteReceipt,
}: {
    receipts?: IReceipt[]
    placeholder?: string
    icon: ReactNode
    disabled?: boolean
    isLoading: boolean
    handleUpdate: (receipt: IReceipt) => void
    onDeleteReceipt: (receiptIndex: number) => void
}) {

    const { t } = useTranslation()
    const [edit, setEdit] = useState(false)

    const handleSave = (receipt: IReceipt) => {
        handleUpdate(receipt)
    }

    let className: string | undefined = "text-secondary"
    if (receipts?.length) {
        className = undefined
    }

    let view = <Row>
        <Col className={className} xs={'auto'}>{icon}</Col>
        <Col className={className} onClick={() => setEdit(true)}>{receipts?.length ? t('changeReceiptsLabel') : placeholder ? placeholder : ''}</Col>
        <Offcanvas show={edit} onHide={() => setEdit(false)}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t('uploadReceiptsTitle')}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ReceiptsUpload
                    disabled={disabled}
                    onChange={handleSave}
                    receipts={receipts}
                    isLoading={isLoading}
                    onDelete={onDeleteReceipt}
                />
            </Offcanvas.Body>
        </Offcanvas>
    </Row>

    return view
}

export default EditReceipts