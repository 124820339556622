import { Button, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { useState } from "react"

function ImportFreeTextForm({
    disabled,
    submitText,
}: {
    disabled: boolean
    submitText: (text: string) => void
}) {

    const { t } = useTranslation()

    const initState = {
        text: '',
    }

    const [initialValues] = useState(initState)

    const onSubmit = async (values: { text: string }) => {
        submitText(values.text)
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        defaultValues: initialValues
    })

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="text">
                <Form.Label>{t('shoppingListFreeText')}</Form.Label>
                <Form.Control
                    type="text"
                    as="textarea"
                    rows={5}
                    autoFocus={true}
                    placeholder={t('shoppingListFreeText')}
                    defaultValue={''}
                    {...register("text", { required: t('shoppingListFreeTextRequiredError') })}
                />
                {errors.text && (
                    <Form.Text className="text-danger">
                        {errors.text.message}
                    </Form.Text>
                )}
            </Form.Group>

            <div className="d-grid gap-2">
                <Button
                    type='submit'
                    variant="primary"
                    disabled={!isValid || disabled}
                >{t('importButtonLabel')}</Button>
            </div>
        </Form>
    )
}

export default ImportFreeTextForm