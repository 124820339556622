import { baseSplitApi } from "../../Services/BaseApi"
import { INotification } from "../../Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listNotifications: build.query<INotification[], void>({
            query: () => '/notifications',
            providesTags: ["Notifications"],
        }),
        countUnreadNotifications: build.query<{ count: number }, void>({
            query: () => '/notifications-count',
            providesTags: ["NotificationsCount"],
        }),
        notificationsMarkRead: build.mutation<void, void>({
            query: () => ({
                url: '/notifications-mark-read',
                method: 'GET',
            }),
            invalidatesTags: ["NotificationsCount", "Notifications"]
        }),
    }),
    overrideExisting: false,
})

export const {
    useListNotificationsQuery,
    useCountUnreadNotificationsQuery,
    useNotificationsMarkReadMutation,
} = api