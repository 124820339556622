import { Col, Offcanvas, Row } from "react-bootstrap"
import { useEffect, useMemo, useState } from "react"
import * as Icon from 'react-bootstrap-icons'
import EventUserAvatars from "../../EventUserAvatars"
import { IMember, IUser } from "../../../Types"
import EntityMembers from "./entity-members"
import OtherUsers from "./other-users"
import Invite from "./invite"
import { useListInvitedUsersQuery } from "../../../Store/UserConfig/UserConfig.service"
import { useTranslation } from "react-i18next"

function EditMembers({
    defaultValue,
    unshare,
    invite,
    shareMobile,
}: {
    defaultValue?: IMember[]
    unshare: (email: string) => void
    invite: (email: string, userId?: string) => void
    shareMobile?: (token: string) => void
}) {

    const { t } = useTranslation()
    const [edit, setEdit] = useState(false)
    const [filtered, setFiltered] = useState<IUser[]>([])

    const usersQuery = useListInvitedUsersQuery()

    useEffect(() => {
        if (!usersQuery.isLoading && usersQuery.isSuccess && usersQuery.data?.length) {
            const userEmails: string[] = defaultValue?.filter(member => !!member.email).map(member => member.email) as string[]
            let data = usersQuery.data
            if (userEmails?.length) {
                data = usersQuery.data.filter(user => !userEmails.includes(user.email))
            }
            setFiltered([...data])
        }
    }, [usersQuery.isLoading, usersQuery.isSuccess, usersQuery.data, defaultValue])

    const className = useMemo(() => {
        let className: string | undefined = "text-secondary"
        if (defaultValue?.length) {
            className = undefined
        }
        return className
    }, [defaultValue])

    const acceptedMembers = useMemo(() => {
        if (!defaultValue?.length) {
            return []
        }
        const members: { userId: string, name: string, picture?: string }[] = []
        for (const member of defaultValue) {
            if (member.userId && member.name) {
                members.push(member as { userId: string, name: string, picture?: string })
            }
        }
        return members
    }, [defaultValue])

    let view = <Row>
        <Col className={className} xs={'auto'}><Icon.People /></Col>
        <Col className={className} onClick={() => setEdit(true)}>
            {acceptedMembers?.length ? <EventUserAvatars users={acceptedMembers} /> : t('addMembersLabel')}
        </Col>
        <Offcanvas show={edit} onHide={() => setEdit(false)}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t('inviteMemberTitle')}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Invite invite={invite} />
                <EntityMembers members={defaultValue} unshare={unshare} shareMobile={shareMobile} />
                <OtherUsers users={filtered} invite={invite} />
            </Offcanvas.Body>
        </Offcanvas>
    </Row>

    return view
}

export default EditMembers