import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useGetEventQuery } from "../../Store/Event/Event.service"
import { skipToken } from "@reduxjs/toolkit/query"
import EditEventPage from "./Edit/index"
import { useTranslation } from "react-i18next"

function EditEvent() {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { eventId } = useParams()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const backUrl = searchParams.has('r') ? searchParams.get('r') : null

    const args = eventId ? { eventId } : skipToken
    const eventQuery = useGetEventQuery(args)

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app')}>{t('homeTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events')}>{t('eventsTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/app/events/' + eventId)}>{eventQuery.data?.title}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{t('editButtonLabel')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>{t('editEventTitle')}</h3>
                </Col>
            </Row>
            {eventQuery.data ? <Row>
                <Col>
                    <EditEventPage event={eventQuery.data} />
                    <div className="text-center">
                        <Button
                            variant="link"
                            onClick={() => navigate(backUrl ?? `/app/events/${eventId}`)}
                        >
                            {t('backButtonLabel')}
                        </Button>
                    </div>
                </Col>
            </Row> : null}
        </Container>
    )
}

export default EditEvent