import { Breadcrumb, Col, Container, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import AddEventForm, { AddEventFormData } from "./add-form"
import { useAddEventMutation } from "../../Store/Event/Event.service"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { ApiError } from "../../Services/BaseApi"
import { useTranslation } from "react-i18next"

function AddEvent() {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const [addEvent, addEventResult] = useAddEventMutation()

    const handleSubmit = async (data: AddEventFormData) => {
        try {
            const event = await addEvent(data).unwrap()
            navigate('/app/events/' + event._id)
        } catch (err) {
            dispatch(setMessage((err as ApiError).data.error))
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate('/app')}>{t('homeTitle')}</Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => navigate('/app/events')}>{t('eventsTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{t('createEventTitle')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AddEventForm
                        disabled={addEventResult.isLoading}
                        handleSubmit={handleSubmit}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default AddEvent