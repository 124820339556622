import { baseSplitApi } from "../../Services/BaseApi"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        uploadImage: build.mutation<{ imageUrl: string }, { base64: string, mimeType: string }>({
            query: (body) => ({
                url: '/images',
                method: 'POST',
                body,
            }),
        }),
        uploadImageUrl: build.mutation<{ imageUrl: string }, string>({
            query: (imageUrl) => ({
                url: '/image-url',
                method: 'POST',
                body: { imageUrl },
            }),
        }),
    }),
    overrideExisting: false,
})

export const {
    useUploadImageMutation,
    useUploadImageUrlMutation,
} = api