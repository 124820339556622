import { Button, Col, Form, Row } from "react-bootstrap"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import Html5QrcodePlugin from "../../Html5QrcodePlugin"

function Scanner({
    disabled,
    onChange,
    sku
}: {
    disabled?: boolean
    onChange: (sku: string) => void
    sku?: string
}) {

    const { t } = useTranslation()
    const [barcode, setBarcode] = useState(sku ?? '')

    return (
        <Row>
            <Col>
                <Row className="mb-3">
                    <Col>
                        <Html5QrcodePlugin
                            qrCodeErrorCallback={err => { }}
                            qrCodeSuccessCallback={setBarcode}
                        />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder={t('setBarcodeButtonLabel')}
                            value={barcode}
                            onChange={e => setBarcode(e.target.value)}
                        />
                    </Col>
                </Row>
                {barcode ? <Row className="mb-3">
                    <Col>{t('scannedBarcodeText')}: {barcode}</Col>
                </Row> : null}
                <Row>
                    <Col>
                        {barcode ? <div className="d-grid gap-2 mb-3">
                            <Button
                                disabled={disabled}
                                variant="primary"
                                onClick={() => onChange(barcode)}
                            >{t('setBarcodeButtonLabel')}</Button>
                        </div> : null}
                        {sku ? <div className="d-grid gap-2 mb-3">
                            <Button
                                disabled={disabled}
                                variant="danger"
                                onClick={() => onChange('')}
                            >{t('clearSkuButtonLabel')}</Button>
                        </div> : null}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Scanner