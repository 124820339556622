import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../Loading";
import LocalStorageService, { LocalStorageKeys } from "../../Services/LocalStorage";
import { setMessage } from "../../Store/Toast/Toast.slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import Navigation from "../Navigation";

function Auth() {

    const dispatch = useDispatch<AppDispatch>()
    const location = useLocation()
    const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();

    const [loggedIn, setLoggedIn] = useState(false)

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect({
                appState: {
                    returnTo: location.pathname,

                },
            })
        }
    }, [isAuthenticated, isLoading, loginWithRedirect, location.pathname])

    useEffect(() => {
        const getUserMetadata = async () => {
            if (isAuthenticated) {
                try {
                    const accessToken = await getAccessTokenSilently();
                    await LocalStorageService.setItem(LocalStorageKeys.AUTH_TOKEN, accessToken)
                    await LocalStorageService.setItem(LocalStorageKeys.USER_ID, user?.sub)
                    setLoggedIn(true)
                } catch (e) {
                    dispatch(setMessage((e as Error).message))
                }
            }
        };

        getUserMetadata();
    }, [getAccessTokenSilently, user?.sub, isAuthenticated, dispatch])

    let view = <div className="d-flex align-items-center  
    justify-content-center vh-100">
        <Loading />
    </div>

    if (loggedIn) {
        view = <Navigation />
    }

    return view
}

export default Auth