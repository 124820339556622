import { useRef, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useUploadImageMutation, useUploadImageUrlMutation } from "../../Store/Image/Image.service"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../Store/Store"
import { setMessage } from "../../Store/Toast/Toast.slice"
import { ApiError } from "../../Services/BaseApi"
import Loading from "../Loading"
import { useTranslation } from "react-i18next"

function ImageUpload({
    onChange,
    disabled
}: {
    onChange: (imageUrl: string) => void
    disabled?: boolean
}) {

    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [imageUrl, setImageUrl] = useState('')
    const [imageBase64, setImageBase64] = useState<{
        base64: string
        mimeType: string
    } | null>(null)

    const [uploadImage, uploadImageResult] = useUploadImageMutation()
    const [uploadImageUrl, uploadImageUrlResult] = useUploadImageUrlMutation()

    const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const inputImageUrlRef = useRef<HTMLInputElement>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = async () => {
        const file = inputRef.current?.files ? inputRef.current.files[0] : null
        if (file) {
            setUploadedFileName(file.name);
            const base64 = await convertBase64(file)
            if (base64) {
                setImageBase64({
                    base64: base64.toString(),
                    mimeType: file.type,
                })
            } else {
                setImageBase64(null)
            }
        }
    };

    const convertBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                const result = fileReader.result as string
                if (!result) {
                    resolve('')
                } else {
                    const parts = result.split(';base64,')
                    if (parts.length > 1) {
                        resolve(parts[1]);
                    } else {
                        resolve(result);
                    }
                }
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const onSave = async () => {
        if (imageUrl) {
            try {
                const response = await uploadImageUrl(imageUrl).unwrap()
                onChange(response.imageUrl)
                setImageUrl('')
                if (inputImageUrlRef.current) {
                    inputImageUrlRef.current.value = ''
                }
            } catch (err) {
                dispatch(setMessage((err as ApiError).data.error))
            }
        } else {
            if (imageBase64) {
                try {
                    const response = await uploadImage(imageBase64).unwrap()
                    onChange(response.imageUrl)
                } catch (err) {
                    dispatch(setMessage((err as ApiError).data.error))
                }
            }
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="imageUrl">
                        <Form.Control
                            type="url"
                            ref={inputImageUrlRef}
                            placeholder={t('imageUrlPlaceholder')}
                            defaultValue={''}
                            onChange={e => setImageUrl(e.target.value)}
                            disabled={disabled || uploadImageResult.isLoading || uploadImageUrlResult.isLoading}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3 text-center">
                <Col>{t('orText')}</Col>
            </Row>
            <Row className="mb-3">
                <Col className="text-center">
                    <label className="mx-3">{t('chooseFileLabel')}:</label>
                    <input
                        ref={inputRef}
                        disabled={disabled || uploadImageResult.isLoading || uploadImageUrlResult.isLoading}
                        onChange={handleDisplayFileDetails}
                        className="d-none"
                        type="file"
                    />
                    <button
                        onClick={handleUpload}
                        disabled={disabled || uploadImageResult.isLoading || uploadImageUrlResult.isLoading}
                        className={`btn btn-outline-${uploadedFileName ? "success" : "primary"}`}
                    >
                        {uploadedFileName ? uploadedFileName : t('uploadButtonLabel')}
                    </button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-grid gap-2 mb-3">
                        <Button
                            variant="primary"
                            disabled={(!imageUrl && !imageBase64) || uploadImageResult.isLoading || uploadImageUrlResult.isLoading}
                            onClick={onSave}
                        >
                            {uploadImageResult.isLoading || uploadImageUrlResult.isLoading ? <>{t('pleaseWaitButtonLabel')} <Loading /></> : t('saveButtonLabel')}
                        </Button>
                    </div>
                </Col>
            </Row>
            {!uploadImageResult.isLoading && !uploadImageUrlResult.isLoading ? <Row>
                <Col>
                    <div className="d-grid gap-2 mb-3">
                        <Button
                            variant="link"
                            disabled={uploadImageResult.isLoading || uploadImageUrlResult.isLoading}
                            onClick={() => onChange('')}
                        >
                            {t('removeImageButtonLabel')}
                        </Button>
                    </div>
                </Col>
            </Row> : null}
        </>
    )
}

export default ImageUpload