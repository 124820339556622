import { Breadcrumb, Button, Card, Col, Container, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useListEventsQuery } from "../../Store/Event/Event.service"
import * as Icon from 'react-bootstrap-icons'
import EventListItem from "../EventListItem"
import TitleButton from "../TitleButton"
import { useTranslation } from "react-i18next"

function Events() {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const eventsQuery = useListEventsQuery()

    return (
        <Container>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/app/')}>{t('homeTitle')}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{t('eventsTitle')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            {eventsQuery.data?.length ? <Row className="mb-3">
                <Col>
                    <TitleButton
                        title={t('shoppingEventsTitle')}
                        buttonText={<><Icon.Plus /> {t('addEventButtonLabel')}</>}
                        onClick={() => navigate('/app/events-add')}
                    />
                </Col>
            </Row> : null}
            {eventsQuery.data?.length ? <Row>
                <Col>
                    {eventsQuery.data.map(event => <EventListItem
                        key={event._id}
                        event={event}
                        canEdit={true}
                        isShared={false}
                    />)}
                </Col>
            </Row> : <Row>
                <Col>
                    <Card>
                        <Card.Body className="text-center">
                            <Card.Text>{t('addFirstEvent')}</Card.Text>
                            <Button
                                variant="primary"
                                onClick={() => navigate('/app/events-add')}
                            ><Icon.Plus /> {t('addEventButtonLabel')}</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>}
        </Container>
    )
}

export default Events